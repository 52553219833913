import $ from 'jquery'

class SelectComponent {
  constructor(selectInputs) {
    selectInputs.each((i, selectInput)=>{
      this.setupSelect(selectInput)
    })

  }

  setupSelect(select) {
    let self = this
    let selectInput = $(select)
    selectInput.on('change', (ev)=>{
      let el = $(ev.target)
      let option = el.find('option:selected')
      el.toggleClass('filled', (option.val() != ''))
    })
  }
}

$(() => {
  let selectInputs = $('.custom-select')
  if (selectInputs){
    new SelectComponent(selectInputs)
  }
})

export default SelectComponent
