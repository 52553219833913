import I18n from "../i18n-js/index.js.erb";
import Preloader from "../lib/preloader";
import { Popup } from "../lib/popup";
import User from "../lib/user";
import ProfileUpdateForm from "../lib/cabinet/profile-update-form";
import { initTooltip } from "../lib/helper";
import ScheduleForm from "../lib/schedule_form";
import ScheduleBetweenStations from "../lib/schedule_between_stations";
import Collapsible from "../lib/collapsible";
import SubMenu from '../lib/sub-menu'
import RailwaySearchNotification from '../lib/railway_search_notification'


class AllPage {
  constructor(page) {
    // window.$ = window.jQuery = $

    I18n.locale = $("body").data("lang");
    window.I18n = I18n;
    window._preloader = new Preloader("round");

    this.scheduleForm = $("#schedule-route-form");
    if (this.scheduleForm.length > 0) {
      this.scheduleForm = new ScheduleForm(this.scheduleForm);
    }

    this.scheduleBetweenStationsForm = $("#schedule-between-stations-form");
    if (this.scheduleBetweenStationsForm.length > 0) {
      this.scheduleBetweenStations = new ScheduleBetweenStations(
        this.scheduleBetweenStationsForm
      );
    }

    $(document).ajaxStart(function() {
      window._preloader && window._preloader.show();
    });

    $(document).ajaxStop(function() {
      window._preloader && window._preloader.hide();
    });

    $(".close-btn").click(function() {
      $(this).magnificPopup("close");
    });

    $(".btn-group button").on("click", function(ev) {
      ev.preventDefault();
      $(this).toggleClass("active");
    });

    new User();
    new SubMenu();
    new ProfileUpdateForm();

    let collapsibleContainers = $(".js-collapsible");

    if (collapsibleContainers.length) {
      collapsibleContainers.each((i, el) => {
        new Collapsible($(el));
      });
    }

    initTooltip();

    let rw_search_notification = new RailwaySearchNotification()
    rw_search_notification.showMessageFirstTime()
    
  }
}

$(() => {
  new AllPage();
});

export default AllPage;
