import {Popup, OkPopup} from './popup'
import Submiter from './submiter'

class ContactForm {
  constructor(form){
    this.form = form
    this.email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    this.name_regex  = /^[A-Za-zА-Яа-яїЇІієЄЭэЙйъёь\- ]+$/;

    this.email = form.find('[name=email]')
    this.name = form.find('[name=name]')
    this.subject = form.find('[name=subject]')
    this.message = form.find('[name=message]')
    this.submitBtn = form.find('.js-submit-contact-form')

    this.initializeEvents()
  }

  initializeEvents(){

    this.submitBtn.on('click', (ev)=>{
      ev.preventDefault()
      if (this.contactFormValid()){
        var self = this;
        grecaptcha.execute(reCAPTCHA_site_key, { action: 'contact' }).then(function (token) {
          // Генерируется во вьюхе с помощью recaptcha_v3
          // надо получать свежий токен перед отправкой формы, так
          // как старый мог протухнуть
          setInputWithRecaptchaResponseTokenForContact('g-recaptcha-response-data-contact', token)
          new Submiter(self.form).done(function(data){
            new OkPopup(data.result.title, data.result.message, ()=>{
              window.location = '/'
            })
          })
        }, function (reason) {
          console.log(reason);
        });
        
      }
    })
  }

  contactFormValid(){
    if (!this.validateEmail()) { return false; }
    if (!this.validateName()) { return false; }
    if (!this.validateMessage()) { return false; }

    // if(this.form.find('[name=g-recaptcha-response]').length && !this.form.find('[name=g-recaptcha-response]').val()){
    //   new Popup('error', I18n.t('waiting_list.form.validate.recaptcha.required')).show();
    //   return false;
    // }
    return true
  }

  validateEmail(){
    if (!!this.email.length){
      if (this.email.val().length == 0){
        new Popup('error', I18n.t('user_email_required')).show();
        return false;
      }
      if (!this.email_regex.test(this.email.val())){
        new Popup('error', I18n.t('user_email_email')).show();
        return false;
      }
    }
    return true;
  };

  validateSubject(){

  }

  validateName(){
    if (!!this.name.length){
      if (this.name.val().length == 0){
        new Popup('error', I18n.t('name_required')).show();
        return false;
      }
      if (!this.name_regex.test(this.name.val())){
        new Popup('error', I18n.t('firstname_lettersonly')).show();
        return false;
      }
    }
    return true;
  }

  validateMessage(){
    if (!!this.message.length){
      if (this.message.val().length == 0){
        new Popup('error', I18n.t('message_required')).show();
        return false;
      }
      if (this.message.val().length > 1000){
        new Popup('error', I18n.t('message_to_long')).show();
        return false;
      }
    }
    return true;
  }
}

$(() => {
  let contact_form = $('.js-contact-form')
  if (contact_form.length) {
    new ContactForm(contact_form)
  }
})