var AgePicker = function(agePickerBlock){
  var ageBlock = $(agePickerBlock);
  var ageField = ageBlock.find('.js-document-age');
  var agePickerBlock = ageBlock.find('.age-picker');
  var agePicker = ageBlock.find('.js-age-day-picker');

  var maxChildBitrh = new Date(ageBlock.data('max'));
  var minChildBitrh = new Date(ageBlock.data('min'));

  agePicker.datepicker({
    changeMonth: true,
    changeYear: true,
    maxDate: minChildBitrh,
    minDate: maxChildBitrh,
    onSelect: function(dateText){
      ageField.val(dateText);
      agePickerBlock.addClass('hidden');
    },
  });

  var maxYear = maxChildBitrh.getFullYear();
  var minYear = minChildBitrh.getFullYear();

  var title = ageBlock.find('.age-picker .title');
  var titleDefaultText = title.text();

  var yearsBlock  = ageBlock.find('.year');
  var monthsBlock = ageBlock.find('.month');
  var arrow       = ageBlock.find('.prev-arrow');

  var dateToday = new Date();
  var year      = dateToday.getFullYear();
  var month     = dateToday.getMonth();

  var position  = 0;

  yearsBlock.find('a').on('click', function(event){
    var $year = $(event.target);
    year = parseInt($year.data('number'));

    monthsBlock.find('a').show();
    var _month = maxChildBitrh.getMonth();
    if (year == minYear){
      monthsBlock.find('a[data-number='+_month+']').nextAll().hide();
    }else if (year == maxYear){
      monthsBlock.find('a[data-number='+_month+']').prevAll().hide();
    }

    title.text($year.text());
    yearsBlock.addClass('hidden').hide();
    monthsBlock.removeClass('hidden').show();
    arrow.removeClass('hidden').show();
    position = 1;
  });
  monthsBlock.find('a').on('click', function(event){
    var $month = $(event.target);
    month = parseInt($month.data('number'));
    title.text($month.text() + ' ' + year);
    agePicker.datepicker("setDate", new Date(year, month, dateToday.getDate()));
    monthsBlock.addClass('hidden').hide();
    agePicker.removeClass('hidden').show();
    arrow.removeClass('hidden').show();
    position = 2;
  });

  arrow.on('click',function(event){
    switch(position) {
    case 1:
      yearsBlock.removeClass('hidden').show();
      monthsBlock.addClass('hidden').hide();
      $(event.target).addClass('hidden').hide();

      title.text(titleDefaultText);
      position = 0;
      break;
    case 2:
      monthsBlock.removeClass('hidden').show();
      agePicker.addClass('hidden');

      title.text(year);
      position = 1;
      break
    default:
      break;
    }
  });

  ageField.on('focus', function(ev){
    agePickerBlock.removeClass('hidden');
  });
  
};

export default AgePicker