class Store {
  get(key){
    let data = sessionStorage[key];
    return data && JSON.parse(data);
  }
  set(key, value){
    sessionStorage[key] = JSON.stringify(value);
    return value;
  }
}

window.store = new Store();

export default Store