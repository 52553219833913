import Train from './train'
import PreCard from './pre-card'
import OrderButton from './order-button'
import {Popup} from '../popup'


var PlaceHelper = function(store){
  this.store = store;
  this.store.subscribers.push(this);
  this.onStoreChange = this.onStoreChange.bind(this);

  this.block = $('.js-choose-place-helper');
  this.block.on('click',function(event){
    new Popup('placeHelper').show();
  });
};
PlaceHelper.prototype.onStoreChange = function(){
  if (this.store.places.length == 0){
    this.block.show();
  }else{
    this.block.hide();
  }
};
var BookingPage = function($train, store){
  this.store        = store;
  this.train        = new Train($train, store);
  this.preCard      = new PreCard(this.store);
  this.orderButton  = new OrderButton(this.store);
  this.placeHelper  = new PlaceHelper(this.store);
};

BookingPage.prototype.showStore = function(){
  this.preCard.onStoreChange();
  this.orderButton.onStoreChange();
}


var BookingPageBoryspil =  function(results, store){
    this.store = store;
    this.store.subscribers.push(this);
    this.orderButton  = new OrderButton(this.store);
    
    this.form               = $('.js-create-basket-form');
    this.formPassBlocks     = this.form.find('.js-borispil-passenger-items');
    this.totalPrice         = $('.js-total-cost-price');
    this.submitButton       = $('.js-add-to-basket-button');
    this.kyivBoryspilTable  = results.find('.js-kyiv-boryspil-timetable-btn');
    this.timetableToggle    = results.find('.js-timetable-toggle');
    this.fullButtonMinus    = results.find('.js-kyiv-boryspil-full-data-minus');
    this.fullButtonPlus     = results.find('.js-kyiv-boryspil-full-data-plus');
    this.childButtonMinus   = results.find('.js-kyiv-boryspil-child-data-minus');
    this.childButtonPlus    = results.find('.js-kyiv-boryspil-child-data-plus');

    this.price = parseFloat($("#js_boryspil_price").val());

    this.newPassBlock       = $('.js-base_passenger').clone();
    $('.js-base_passenger').remove();

    this.passengersForm     = new PassengersFrom(this.form, this.store);

    this.initializeEvents(results);
    this.setDefaultBooking();
};
BookingPageBoryspil.prototype.setDefaultBooking = function(){
    this.store.addPlace({
      train: 1, 
      wagon: 1,
      type: 'full',
      cost: {amount: parseFloat($("#js_boryspil_price").val())}
    })
}

BookingPageBoryspil.prototype.initializeEvents = function(results){
  this.kyivBoryspilTable.on('click', render.kyivBoryspilTimeTable);
  this.timetableToggle.on(  'click', render.kyivBoryspiltimetableToggle)
  this.fullButtonMinus.on( 'click', this.fullDataMinus.bind(this));
  this.fullButtonPlus.on(  'click', this.fullDataPlus.bind(this));
  this.childButtonMinus.on( 'click', this.childDataMinus.bind(this));
  this.childButtonPlus.on(  'click', this.childDataPlus.bind(this));

  this.submitButton.on('click', this.submit.bind(this));
};

BookingPageBoryspil.prototype.valid = function(){
  var time = new Validation('time', this.form.find('.js-time'));
  if(!time.isValid()){
    time.showError();
    return false;
  }
  
  if(!this.passengersForm.valid()){
    return false;
  }

  return true;
};

BookingPageBoryspil.prototype.submit = function(event){
  if (this.valid()){
    render.basket(this.form);
  };    
};

BookingPageBoryspil.prototype.onStoreChange = function(){
  var total = 0;
  $.each(this.store.places, function(index, storedPlace){
    total += storedPlace.cost.amount;
  });
  if(total > 0){
    this.totalPrice.text(render.total_price(total));
  }
};

BookingPageBoryspil.prototype.fullDataPlus = function(event){
  this.dataPlus(event, 'full');
};
BookingPageBoryspil.prototype.fullDataMinus = function(event){
  var full = this.formPassBlocks.find('.js-full').last();
  if ( !!full.length ) this.dataMinus(event, full);
};
BookingPageBoryspil.prototype.childDataPlus = function(event){
  this.dataPlus(event, 'child');
};
BookingPageBoryspil.prototype.childDataMinus = function(event){
  var child = this.formPassBlocks.find('.js-child').last();
  if ( !!child.length ) this.dataMinus(event, child);
};

BookingPageBoryspil.prototype.reloadPassNumbers = function(){
  var self = this;
  $.each(this.formPassBlocks.find('.js-user-data'), function(index, block){
    var addPassBlock = $(block);
    addPassBlock.find('.js-document-lastname').attr('name', 'documents['+index+'][lastname]');
    addPassBlock.find('.js-document-firstname').attr('name', 'documents['+index+'][firstname]');
    addPassBlock.find('.js-document-type').attr('name', 'documents['+index+'][document_type]');
    addPassBlock.find('.js-user-data-title').text(I18n.t('passenger_block.title', {number: index+1, type: I18n.t('passenger_block.type.'+addPassBlock.find('.js-document-type').val())}));
    
    addPassBlock.find('.js-boryspil-cancel-passenger').remove();
    if (index > 0){
      var declineDocument = addPassBlock.find('.decline-order');
      declineDocument.append('<a class="popup-with-move-anim js-boryspil-cancel-passenger">'+I18n.t('passenger_block.cancel')+'</a>');
      declineDocument.find('.js-boryspil-cancel-passenger').on('click', function(event){
        event.preventDefault();
        self.dataMinus(event, addPassBlock);
      });
    }
  });
};

BookingPageBoryspil.prototype.dataMinus = function(event, addPassBlock){
  var type = addPassBlock.find('.js-document-type').val();
  var counter = parseInt($(".js-boryspil-user-counter-hidden").val());
  var show_counter  = parseInt($('.js-counter').data(type));
  if(counter>1 && ((show_counter > 0 && type == 'child') || (show_counter > 0 && type == 'full'))){
    this.store.deletePlace({
      train: 1, 
      wagon: 1,
      type: type,
    })  
    counter--;
    $(".js-boryspil-user-counter-hidden").val(counter);
    show_counter--;
    $('.js-counter').data(type, show_counter);
    $('.js-boryspil-'+type+'-counter').text(show_counter);
    addPassBlock.remove();
    this.reloadPassNumbers();
  }
}
BookingPageBoryspil.prototype.dataPlus = function(event, type){
  var type = type || 'full';
  var self = this;
  var counter       = parseInt($(".js-boryspil-user-counter-hidden").val());
  var show_counter  = parseInt($('.js-counter').data(type));
  if(counter<=8){
    counter++;
    this.store.addPlace({
      train: 1, 
      wagon: 1,
      type: type,
      cost: {amount: this.price}
    })
    $(".js-boryspil-user-counter-hidden").val(counter);
    show_counter++;
    $('.js-counter').data(type, show_counter);
    $('.js-boryspil-'+type+'-counter').text(show_counter);
    var addPassBlock = this.newPassBlock.clone();
    addPassBlock.removeClass('js-base_passenger');
    addPassBlock.addClass('js-user-data js-'+type);
    addPassBlock.data('type', type);
    addPassBlock.find('.js-document-type').val(type);
    
    this.formPassBlocks.append(addPassBlock.show());
    this.reloadPassNumbers();
  }
}

export default BookingPage