
class Collapsible {
  constructor (collapsibleContainer) {
    this.collapsibleContainer = collapsibleContainer

    this.toggleButton = collapsibleContainer.find('.js-collapsible-button')
    this.collapsibleBlock = collapsibleContainer.find('.js-collapsible-block')

    this.collapsedState = true

    this.textShow = this.toggleButton.data('text-show')
    this.textHide = this.toggleButton.data('text-hide')

    this.setupButtonEvent()
  }

  setupButtonEvent() {
    this.toggleButton.on('click', (ev)=>{
      this.collapsedState = !this.collapsedState

      this.toggleButton.text(this.collapsedState ? this.textShow : this.textHide)
      if (this.collapsedState) {
        this.collapsibleBlock.addClass('collapsed')
        this.toggleButton.addClass('collapsed')
      } else {
        this.collapsibleBlock.removeClass('collapsed')
        this.toggleButton.removeClass('collapsed')
      }
    })
  }
}

export default Collapsible