
import {OkPopup} from '../lib/popup'
import {get as getCookie, set as setCookie } from 'es-cookie';

class RailwaySearchNotification {
  constructor () {
    this.message = window.railway_search_notification;
  }

  showMessageFirstTime() {
    if (this.messageNeedToShow()) {
      this.showMessage()
      setCookie('railway_search_notification', '1', {expires: this.expiresAt()})
    }
  }

  showMessage() {
    new OkPopup(I18n.t('attention'), this.message, ()=>{});
  }

  messageEmpty() {
    return this.message == ''
  }

  messageNeedToShow() {
    return (!this.messageEmpty() && (!this.isCookiePresent() || this.isSearchPage()))
  }

  isCookiePresent() {
    return getCookie('railway_search_notification') == '1'
  }

  isSearchPage() {
    return window.location.pathname == '/search'
  }

  expiresAt() {
    let tmp_date = new Date()
    tmp_date.setMinutes(tmp_date.getMinutes() + 30)
    return tmp_date
  }
}

export default RailwaySearchNotification