var Breadcrumbs = function(){
  this.block      = $('.js-breadcrumbs');
  this.toTrainsListLink = this.block.find('.js-return-link');
  this.steps      = this.block.find('.steps a');
  this.cssClasses = {active: 'active', current: 'current', disabled: 'disabled'};
  this.publicMethods();
  this.initializeEvents();
};
Breadcrumbs.prototype.publicMethods = function(){
  this.show       = this.show.bind(this);
  this.hide       = this.hide.bind(this);
  this.zeroStep   = this.zeroStep.bind(this);
  this.firstStep  = this.firstStep.bind(this);
  this.secondStep = this.secondStep.bind(this);
  this.thirdStep  = this.thirdStep.bind(this);
};
Breadcrumbs.prototype.show = function(){
  this.block.show();
};
Breadcrumbs.prototype.hide = function(){
  this.block.hide();
};
Breadcrumbs.prototype.remove = function(){
  this.block.remove();
};
Breadcrumbs.prototype.initializeEvents = function(){
  this.toTrainsListLink.off();
  this.toTrainsListLink.on('click', function(event){ render.backToTrainsList(event)});
};
Breadcrumbs.prototype.zeroStep = function(){
  this.steps.removeClass('active current disabled').addClass('disabled');
  this.steps.off();
};
Breadcrumbs.prototype.firstStep  = function(){
  this.zeroStep();
  $(this.steps[0]).removeClass(this.cssClasses.disabled).addClass(this.cssClasses.current);
};
Breadcrumbs.prototype.secondStep = function(){
  this.zeroStep();
  $(this.steps[0]).removeClass(this.cssClasses.disabled).addClass(this.cssClasses.active);
  $(this.steps[1]).removeClass(this.cssClasses.disabled).addClass(this.cssClasses.current);
  $(this.steps[0]).on('click', render.backToFirstTrain );
};
Breadcrumbs.prototype.thirdStep  = function(){
  this.zeroStep();
  $(this.steps[0]).removeClass(this.cssClasses.disabled).addClass(this.cssClasses.active);
  $(this.steps[1]).removeClass(this.cssClasses.disabled).addClass(this.cssClasses.active);
  $(this.steps[2]).removeClass(this.cssClasses.disabled).addClass(this.cssClasses.current);
  $(this.steps[0]).on('click', render.backToFirstTrain );
  $(this.steps[1]).on('click', render.backToSecondTrain );
};


export default Breadcrumbs