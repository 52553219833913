var SchemaSVG = function($schema, store){
  this.wagonSchema = $schema;
  this.placeStyle  = {  occupied:  'occupied',
                        selected:  'selected',
                        available: 'available',};
  this.store = store;
  this.store.subscribers.push(this);
  this.onStoreChange = this.onStoreChange.bind(this);

  this.schemaPlaces = this.wagonSchema.find('svg g').not('#wagon,#car_number,#Wagon');
  this.compareSchemaWithStore();

  this.places = {
    occupied: this.schemaPlaces.filter('.'+this.placeStyle.occupied),
    selected: this.schemaPlaces.filter('.'+this.placeStyle.selected),
    available: this.schemaPlaces.filter('.'+this.placeStyle.available),
  };

  this.initializeEvents();
};
SchemaSVG.prototype.placeOnSchemaByStored = function(storedPlace, schemaPlaces){
  var schemaPlaces = schemaPlaces || this.schemaPlaces;
  var self = this;
  return schemaPlaces.filter(function(_index,place){
    return self.store.placesEqual(storedPlace, $(place).data());
  });
};
SchemaSVG.prototype.compareSchemaWithStore = function(){
  var self = this;
  $.each(this.store.places, function(index, storedPlace){
    var placeOnSchema = self.placeOnSchemaByStored(storedPlace);
    if (placeOnSchema){
      var $placeOnSchema = $(placeOnSchema);
      if ($placeOnSchema.hasClass(self.placeStyle.occupied)){
        self.store.deletePlace($placeOnSchema.data());
      } else {
        $placeOnSchema.addClass(self.placeStyle.selected);
      }
    }
  })
};
SchemaSVG.prototype.onStoreChange = function(){
  var self = this;
  var selectedOnSchema = this.schemaPlaces.filter('.'+this.placeStyle.selected);
  $.each(selectedOnSchema, function(index, placeOnSchema){
    var $placeOnSchema = $(placeOnSchema);
    if (!self.store.findInStore($placeOnSchema.data())){
      $placeOnSchema.removeClass(self.placeStyle.selected);
    }
  });
};
SchemaSVG.prototype.initializeEvents = function(){
  var self = this;
  this.places.available.on('click', function(event){
    var selector = '.'+self.placeStyle.selected+',.'+self.placeStyle.available;
    var place = $(event.target).is(selector) ? $(event.target) : $(event.target).parent();
    if (place.hasClass(self.placeStyle.selected)){
      self.deselectPlace(place);
    } else if (place.hasClass(self.placeStyle.available)){
      self.selectPlace(place);
    }
  });
};
SchemaSVG.prototype.selectPlace = function(place){
  this.store.addPlace(place.data()) && place.addClass(this.placeStyle.selected);
};
SchemaSVG.prototype.deselectPlace = function(place){
  this.store.deletePlace(place.data());
  place.removeClass(this.placeStyle.selected);
};

export default SchemaSVG