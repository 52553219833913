export function initTooltip(selector){
  var selector = selector || '.js-tooltip-text';
  $(selector).tooltipster({
    delay: 200,
    trigger: 'custom',
    triggerOpen: {
      tap: true,
      mouseenter: true
    },
    triggerClose: {
      mouseleave: true,
      tap: true,
      scroll: true
    },
    maxWidth: 300,
    functionPosition: function(instance, helper, position){
      if(!helper.origin.classList.contains('js-tooltip-without-ofsset')){
        position.coord.left += 135;
      }
      return position;
    }
  });
}

export function scrollTo(element, callback) {
  $([document.documentElement, document.body]).animate({
    scrollTop: element.offset().top
  }, 250, function(){
    callback()
  }); 
}