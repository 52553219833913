// let plusToogle = document.querySelector(".tooglePlus");
// let rotateToogle = document.querySelector(".toogleRotate");

// plusToogle.addEventListener("click", (evt) => {
//   rotateToogle.style.display = "block";
// });
console.log("hello");
class Faq {
  constructor(container) {
    this.container = container;
    this.setupAccordion();
  }

  setupAccordion() {
    this.container.find(".js-faq-question").on("click", (ev) => {
      let faqTitle = $(ev.target);
      let faqContent = faqTitle.next(".js-faq-answer");
      faqContent.slideToggle();
    });
  }
}

$(() => {
  let faqContainer = $(".js-faq-container");
  if (faqContainer.length) {
    new Faq(faqContainer);
  }
});
