import {Popup, ConfirmPopup, OverAllPopup} from './popup'
import Submiter from './submiter'
import {initTooltip, scrollTo} from './helper'
import DataLayerHelper from './data_layer_helper'

var Basket = function($basket){
  scenario.setBasketNotEmpty();
  this.clsPaymentForm = 'js-bus-payment-form';

  this.block = $basket;
  this.backButton     = this.block.find('.js-choose-back');
  this.cancelLinks    = this.block.find('.js-cancel-document');
  this.timers         = this.block.find('.js-timers');
  this.busPaymentBtn  = this.block.find('.js-bus-payment-btn');

  this.clsActive            = 'active';
  this.clsExpandableText    = 'js-expandable-text';

  scenario.setParent(scenario.isRoundtrip() && this.backButton.data('parent'));

  this.buyShippingButton = this.block.find('.js-buy-shipping-button');

  this.payButton = $('.js-pay-button-container .js-user-data-button');
  this.userDataPopup = $('.user-data-popup_v2');
  this.emailDuplicate = $('.js-email-duplicate');
  this.phoneNumberDuplicate = $('.js-phone-number-duplicate');
  this.phoneCodeDuplicate = $('.js-phone-code-duplicate');

  
  this.dataLayer = new DataLayerHelper

  this.initializeEvents();
};
Basket.prototype.initializeEvents = function(){
  var self = this;
  
  this.transportationFormEvents();

  this.emailDuplicate.on('change', function(ev){
    var val = $(ev.target).val().toLowerCase()

    $('.js-email-duplicate-dest').val(val)
  });

  this.phoneCodeDuplicate.on('change', function(e){
    var val = $(e.target).val();

    $('.js-phone-code-duplicate-dest').val(val);
  });

  this.phoneNumberDuplicate.on('change', function(ev){
    var val = $(ev.target).val()

    $('.js-phone-number-duplicate-dest').val(val)
  });

  this.cancelLinks.on('click',function(event){
    let cancelForm = $(event.target).siblings('.js-cancel-form');
    new ConfirmPopup(I18n.t('decline_popup.cancel_document'), function(answer){
      if(answer === 'yes'){
        render.reloadBasket(cancelForm);
      }
    });
  });

  this.buyShippingButton.on('click', function(event){
    event.preventDefault();
    new Popup('addShipping').show();
  });

  this.payButton.on('click', function(event){
    event.preventDefault();
    new Popup('userData').show();
  });

  this.initializeTimers();

  this.initializeBackButton();


  this.userDataPopup.find('button').on('click',function(event){
    event.preventDefault();
    var form = $(event.target).parents('.js-pay-user-form');
    let formValid = self.agreeFormValid(form)

    self.dataLayer.push({ ecommerce: null })
    self.dataLayer.push({
      event: (formValid) ? "Pay button success" : "Pay button error" ,
      currency: self.getGTMCurrencyForDataLayer(),
      value: self.getGTMRevenueForDataLayer(),
      ecommerce: self.getGTMEcommerceForDataLayer()
    })

    if (formValid){
      new Submiter(form).done(function(data){
        setTimeout(function(){new Preloader('round').show();}, 10);
        $.magnificPopup.close();
        window.location = data.result;
      });
    }

  });

  this.busPaymentBtn.on('click', function(e) {
    e.preventDefault();
    let form = $(e.target).closest(`.${self.clsPaymentForm}`);
    let formValid = self.agreeFormValid(form);

    if(formValid){
      new Submiter(form).done(function(data){
        setTimeout(function(){new Preloader('round').show();}, 10);
        $.magnificPopup.close();

        if(data.error){ new Popup('attention', data.error).show(); }
        window.location = data.result;
      });
    }
  });

  this.phoneNumberDuplicate.inputmask({ 
    mask: this.phoneNumberDuplicate.data('mask'), 
    autoUnmask: true
  });

  this.block.on('click', `.${self.clsExpandableText}`, function(e) {
    e.preventDefault();
    $(e.target).toggleClass(self.clsActive);
  });

  initTooltip();
};
Basket.prototype.getGTMEcommerceForDataLayer = function(){
  return this.block.find('.js-data-layer').toArray().map((doc,i)=>{
    let dataLayerData = $(doc);
    return {
      item_id: dataLayerData.data('train'),
      price: dataLayerData.data('comission'),
      wagon_type: dataLayerData.data('wagontypeid'),
    }
  })
};
Basket.prototype.getGTMRevenueForDataLayer = function(){
  return this.block.find('.js-data-layer').toArray().reduce((res, doc)=>{
    let dataLayerData = $(doc);
    return res + (parseFloat(dataLayerData.data('comission')) || 0)
  }, 0);
};

Basket.prototype.getGTMCurrencyForDataLayer = function(){
  let layer = this.block.find('.js-data-layer').toArray()[0];
  return $(layer).data('currency');
};

Basket.prototype.agreeFormValid = function(form){
  var checkbox = form.find('[name=accept_term]');
  if(checkbox.length > 0 && !checkbox.is(':checked')){
    scrollTo(checkbox, ()=> {
      new OverAllPopup(I18n.t('agree_payment_form.validation.agree.required')).show();
    })
    return false;
  }
  
  var bus_accept_term = form.find('[name=bus_accept_term]');
  if(bus_accept_term.length > 0 && !bus_accept_term.is(':checked')){
    scrollTo(bus_accept_term, ()=> {
      new OverAllPopup(I18n.t('agree_payment_form.validation.agree.required')).show();
    })
    return false;
  }

  var email = form.find('[name=email]');
  if(!email.val().length){
    scrollTo(email, ()=> {
      new OverAllPopup(I18n.t('agree_payment_form.validation.email.blank')).show();
    });
    return false;
  }
  var phone_code = form.find('[name=phone_code]');
  if(phone_code.val().length == ''){
    scrollTo(phone_code, ()=>{
      new OverAllPopup(I18n.t('agree_payment_form.validation.phone_code.blank')).show();
    })
    return false;
  }
  var phone = form.find('[name=phone]');
  if(phone.val().length != phone[0].minLength){
    scrollTo(phone, ()=>{
      new OverAllPopup(I18n.t('agree_payment_form.validation.phone_number.blank')).show();
    })
    return false;
  }
  
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if(!re.test(email.val())){
    scrollTo(email, ()=> {
      new OverAllPopup(I18n.t('agree_payment_form.validation.email.valid')).show();
    });
    return false;
  }

  if(form.find('[name=g-recaptcha-response]').length && !form.find('[name=g-recaptcha-response]').val()){
    new OverAllPopup(I18n.t('waiting_list.form.validate.recaptcha.required')).show();
    return false;
  }

  return true;
};

Basket.prototype.transportationFormEvents = function(){
  var form = this.block.find('.js-add-shipping-document');
  var attention = form.find('.js-animal-attention');
  form.find('[name=kind]').on('click', function(event){
    $(event.target).val() === 'С' ? attention.show() : attention.hide();
  });
  form.find('button').on('click',function(event){
    $.magnificPopup.close();
    render.reloadBasket(form);
  });
};
Basket.prototype.initializeTimers = function(){
  scenario.setCancelTimers($.map(this.block.find('.js-timers'),function(item, index){
    const $timer = $(item);
    const seconds = $timer.data('start');
    const deadline = Date.now() + seconds*1000;
    const countdown = function(){
      const secondsCountdown = Math.floor((deadline - Date.now())/1000);
       if(secondsCountdown >= 0){
        const minutes = Math.floor(secondsCountdown/60);
        let remainingSeconds = secondsCountdown % 60;
        if (remainingSeconds < 10) {
            remainingSeconds = "0" + remainingSeconds;
        }
        $timer.text(minutes+":"+remainingSeconds);
      }else{
        clearInterval(countdownTimer);
        const item = $timer.parents('.js-basket-item');
        const form = item.find('.js-cancel-form');
        form.find('[name=cancel]').val('system');
        const message = I18n.t('error_time_to_pay');
        if(item.hasClass('transportation')){
          item.hide();
        }else{
          new Popup('attention',message).show();
          render.reloadBasket(form);
        }
        $timer.parent('.time').text('');
        form.parent('.cancel').text(message);
      }
    };

    const countdownTimer = setInterval(function(){countdown();}, 1000);
    return countdownTimer;
  }).join('|'));
};
Basket.prototype.initializeBackButton = function(){
  if($('.js-search-form').is(':visible')){
    this.backButton.on('click', render.backSearchForm);
  }else{
    this.backButton.hide();
  }
};

export default Basket