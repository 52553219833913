var Preloader = function(type, data){
  this[type](data);
};
Preloader.prototype.show = function(){
  this.preloader.show();
  this.preloader.removeAttr('hidden')
};
Preloader.prototype.hide = function(){
  this.preloader.hide();
  this.preloader.attr('hidden', true)
};
Preloader.prototype.round = function(data){
  this.preloader = $('.js-round-preloader');
};
Preloader.prototype.train = function(data){
  this.preloader = $('.js-preloader');
  this.preloader.find('.js-title').text(data.title);
  this.preloader.find('.js-from').text(data.from);
  this.preloader.find('.js-to').text(data.to);
};
Preloader.prototype.fake = function(){
  this.preloader = $('.js-fake');
};

export default Preloader