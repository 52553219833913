var PreCard = function(store){
  this.store = store;
  this.store.subscribers.push(this);
  this.onStoreChange = this.onStoreChange.bind(this);

  this.mainBlock  = $('.js-pre-card');
  this.trainBlock = this.mainBlock.find('.js-pre-cart-list').clone();
                    this.mainBlock.find('.js-pre-cart-list').remove();
  this.itemBlock  = this.trainBlock.find('.js-stored-place').clone();
                    this.trainBlock.find('.js-stored-place').remove();
};
PreCard.prototype.onStoreChange = function(){
  if (this.store.places.length == 0){
    this.mainBlock.hide();
    return;
  }
  this.mainBlock.find('.js-stored-place').remove();
  this.mainBlock.find('.js-pre-cart-list').remove();

  var self = this;
  var trains = this.store.groupPlaceBy('train');

  var amountClients =  Math.max.apply(Math, Object.keys(trains).map(function(places){return trains[places].length}) );

  var train_index = 1;
  for(var number in trains){
    var train = self.defineTrain(number);

    if(Object.keys(trains).length > 1) train.find('.js-train-index').text(train_index);
    train.find('.js-train-number').text(number);
    trains[number].sort(function(a,b){return a.cargo});
    var cargo_count = trains[number].filter(function(a){return a.cargo}).length;

    for (var i = 0; i < amountClients; i++) {
      train.find('.js-stored').append(self.newItem(trains[number][i], i, train_index));
    }
    
    var price = trains[number].reduce(function(sum, place) {return sum + place.cost.amount}, 0);
    train.find('.js-pre-cart-price').html(render.total_price(price));

    self.mainBlock.append(train);
    $('.js-passenger.js-cargo-description').first().text(I18n.t('cargo_items') + cargo_count);

    train_index += 1;
  };

  this.mainBlock.show();
};

PreCard.prototype.defineTrain = function(number){
  var train = this.mainBlock.find('.js-pre-cart-list[data-train="'+number+'"]');
  if (train.length == 0) train = this.trainBlock.clone();
  train.attr('data-train', number);
  return train;
};

PreCard.prototype.newItem = function(place, index, train_index){
  var self = this;
  var newItemBlock = this.itemBlock.clone();
  newItemBlock.attr('data-store-index', index);
  if(train_index == 1) newItemBlock.find('.js-passenger').text(I18n.t('passenger') + " " + (index+1)).addClass('show');

  if(!place) return newItemBlock;
  
  newItemBlock.find('.js-wagon').text(I18n.t('wagon') + " " + place.wagon);
  if(train_index == 1){
    if(place.cargo){
      newItemBlock.find('.js-passenger').addClass('js-cargo-description');
      newItemBlock.find('.js-passenger').text("");
    }
  }else{
    newItemBlock.find('.js-passenger').hide();
  }

  if (!!parseInt(place.place)) {
    newItemBlock.find('.js-place').text(I18n.t('place') + " " + parseInt(place.place));
  }

  if (!!parseInt(place.place)) {
    newItemBlock.find('.js-price').text( render.total_price(place.cost.amount) );
  }

  newItemBlock.find('.js-close').on('click',function(event){
    self.store.deletePlace(place);
  });

  newItemBlock.find('.js-close').removeClass('hidden');

  return newItemBlock;
};

export default PreCard