var Results = function($results){
  this.results              = $results;
  this.searchWithTransfer   = $('.js-search-with-transfers-button');
  this.searchDirectTrains   = $('.js-search-direct-trains-button');
  this.togglePlaceLinks     = this.results.find('.js-toggle-place');
  this.sortableColumnLinks  = this.results.find('th .sortable');
  this.showRouteLinks       = this.results.find('.js-show-route');
  this.choosePlaceButtons   = this.results.find('.js-choose-places');
  this.noPlacesTrains       = this.results.find('.js-no-place-row');
  this.toggleTrains         = this.toggleTrains.bind(this);
  this.sortByColumn         = this.sortByColumn.bind(this);
  this.resultsTable         = this.results.find('table');
  this.allTrainFull         = this.resultsTable.length == 0 || this.resultsTable.data('noSeats');
  this.allTrainFullInfo     = this.results.find('.js-all-train-full-info');
  this.kyivBoryspilTable          = this.results.find('.js-kyiv-boryspil-timetable-btn');
  this.choosePlaceButtonBoryspil  = this.results.find('.js-choose-places-boryspil');
  this.timetableToggle            = this.results.find('.js-timetable-toggle');
  this.initialize();
  if (this.allTrainFull) this.resultsTable.hide();
};
Results.prototype.initialize = function(){
  this.showRouteLinks.on(     'click', render.routePopup);
  this.togglePlaceLinks.on(   'click', this.toggleTrains);
  this.choosePlaceButtons.on( 'click', render.train);
  this.searchWithTransfer.on( 'click', render.resultWithTransfers);
  // this.searchDirectTrains.on( 'click', searchForm.submit);
  this.sortableColumnLinks.on('click', this.sortByColumn);
  this.kyivBoryspilTable.on('click', render.kyivBoryspilTimeTable);
  this.choosePlaceButtonBoryspil.on('click', render.kyivBoryspilUserData);
  this.timetableToggle.on('click', render.kyivBoryspiltimetableToggle)

  this.sortTable({key: 'departure', dir: 'asc'});
};
Results.prototype.sortTable = function(sortable){
  return function(a,b){
    var key = 'data-sort-'+sortable['key'];
    var asc = sortable['dir'] == 'asc'  ? -1 : 1;
    var a_k = parseInt($(a).attr(key));
    var b_k = parseInt($(b).attr(key));
    return (a_k < b_k) ? asc : (a_k > b_k) ? asc * -1 : 0;
  };
};
Results.prototype.toggleTrains = function(event){
  var element           = $(event.target);
  var allTrainFull      = element.data('no-seats');
  if(element.data('all')){
    this.noPlacesTrains.removeClass('no-place-row');
    if(allTrainFull) {
      this.allTrainFullInfo.hide();
      this.resultsTable.show();
    }
  }else{
    this.noPlacesTrains.addClass('no-place-row');
    if(allTrainFull) {
      this.resultsTable.hide();
      this.allTrainFullInfo.show();
    }
  }
  element.removeClass('active').siblings('.js-toggle-place').addClass('active');
};
Results.prototype.sortByColumn = function(event){
  var column   = $(event.target);
  var params   = column.data();
  var items    = this.results.find('.js-result-item');

  items.sort(this.sortTable(params));
  items.appendTo($('.search-result-table'));

  this.sortableColumnLinks.removeClass('desc');

  column.data('dir', (params['dir'] == 'asc' ? 'desc' : 'asc'));
  column.addClass(column.data('dir'));
};

export default Results