import Submiter from '../submiter'
import Spinner from '../../lib/spinner'

var Train = function($train, store){
  this.train        = $train;
  this.store        = store;
  
  this.spinner = new Spinner

  this.initializeTrain();
  this.initializeWagons();
  this.initializeSelectedPlaces();
};
Train.prototype.initializeTrain = function(){
  var self = this;
  $('.js-car-scheme-container .js-choose-wagon-class').show();
  this.train.find('.js-show-route').on('click', (ev)=>{
    ev.preventDefault()
    let form = $(ev.target).siblings('.js-route-form')
    let modal = $('#routeModal')
    this.spinner.show()

    $.ajax({
      method: form.attr('method'),
      data: form.serialize(),
      url: form.attr('action'),
      success: (data, code, xhr) => {
        this.spinner.hide()
        modal.find('.modal-body').html(data)
        modal.modal()
      },
      error: ()=>{
        this.spinner.hide()
      }
    })
  })
  this.train.find('.js-choose-places').on('click', function(event){
    $('.js-choose-place-helper').show();
    self.chooseWagonType($(event.target));
  });
  $('.js-car-scheme-container-diya').find('.js-choose-places').on('click', function(event){
    $('.js-choose-place-helper').show();
    self.chooseWagonType($(event.target));
  });  
};

Train.prototype.chooseWagonType = function(button){
  var self = this;
  var form = button.siblings('form');
  new Submiter(form).done(function(data){
    $('.js-car-scheme-container .js-choose-wagon-class').hide();
    $('.js-choose-second-train').removeClass('hidden');
    $('.js-wagons-list').replaceWith(data);
    if(button.parents('.js-car-scheme-container-diya').length){
      button.parents('.js-car-scheme-container-diya').find('.js-choose-places').removeClass("wagon-types__item--active");
      button.addClass("wagon-types__item--active");
    }
    self.initializeWagons();
  });
}

Train.prototype.initializeWagons = function(){
  this.wagonsBlock    = $('.js-wagons-list');
  this.wagons         = this.wagonsBlock.find('.js-wagon-item');

  if(this.wagons.length > 0){
    render.schema(this.wagons.first(), this.store);
  }

  var self = this;
  this.wagons.on('click', function(event){
    self.wagons.removeClass('current');
    self.wagons.parent().removeClass('wagon-tab--active');
    var clickedWagon = $(event.target).hasClass('js-wagon-item') ? $(event.target) : $(event.target).parent();
    render.schema(clickedWagon, self.store);
  });
};

Train.prototype.initializeSelectedPlaces = function(){
  var places = this.store.groupPlaceBy('train');
  var train = this.train.find('#train').val();
  
  if(places[train]){
    var wagonTypeId = places[train][0].wagonTypeId;
    var button = this.train.find('[data-wagon-type="'+wagonTypeId+'"]');
    this.chooseWagonType(button);
  }
}


export default Train