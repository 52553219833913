import Consumer from '../../lib/channels/consumer';
import Submiter from '../../lib/submiter';
import {get as getCookie} from 'es-cookie';
import {Popup} from '../../lib/popup';
import Validation from '../../lib/validation';
import Preloader from '../../lib/preloader';

class BusSearchResults {
  constructor($results) {
    this.results          = $results
    this.resultBlock      = this.results.find('.js-search-result');
    this.busNoResultBlock = this.results.find(`.js-bus-no-result`);
    this.preloader        = new Preloader('round');
    this.isBusResult      = false;

    this.clsHidden            = 'hidden';
    this.clsActive            = 'active';
    this.clsSelected          = 'selected'
    this.clsTripDetailsLink   = 'js-trip-details';
    this.clsTripDetails       = 'js-trip-full-info';
    this.clsTrain             = 'js-train';
    this.clsReturnConditions  = 'js-return-conditions';
    this.clsRoutes            = 'js-routes';
    this.clsRouteList         = 'js-route-list';
    this.clsChooseSeat        = 'js-choose-seat';
    this.clsSelectedPlaces    = 'js-selected-places';
    this.clsSelectedContainer = 'js-selected-container';
    this.clsSeatContainer     = 'js-seat-container';
    this.clsCancelSelectSeat  = 'js-cancel-seat-select';
    this.clsPassengerNumber   = 'js-passenger-number';
    this.clsChooseAnotherBus  = 'js-choose-another-bus';
    this.clsTotalAmount       = 'js-total-amount';
    this.clsChooseBusForm     = 'js-choose-bus-form';
    this.clsChooseBusBtn      = 'js-choose-bus-btn';
    this.clsPassengerInfoForm = 'js-passenger-info-form';
    this.clsPassengerInfoBtn  = 'js-passenger-info-btn';
    this.clsResultPreloader   = 'js-bus-search-result_preloader';
    this.clsShowMore          = 'js-show-more';
    this.clsReducePassCount   = 'js-reduce-pass-count';
    this.clsIncreasePassCount = 'js-increase-pass-count';
    this.clsPassCount         = 'js-pass-count';
    this.clsPassCountInput    = 'js-pass-count-input';
    this.clsBusSchema         = 'js-bus-schema';
    this.clsReservationBtn    = 'js-reservation-btn';
    this.clsReservationForm   = 'js-reservation-form';
    this.clsNoResult          = 'js-bus-no-result';
    this.clsShowBusStops      = 'js-show-bus-stops';
    this.clsBusStop           = 'js-bus-stop';
    this.clsExpandableText    = 'js-expandable-text';
    this.clsSelectDiscount    = 'js-select-discount';
    this.clsSortResultTable   = 'js-sort-result-table';

    this.showMoreLink         = document.querySelector(`.${this.clsShowMore}`);

    this.initSocketEvents();
    this.initEvents();
  }
  initSocketEvents(){
    let self = this;
    this.socket = Consumer.subscriptions.create({
      channel:        "BusResultChannel",
      user_token:     getCookie('user_token')
    }, {
      connected(data) {
        console.log('connected')
      },

      disconnected(data) {
        console.log('disconnected');
      },

      received(data) {
        switch ( data.type ) {
          case 'result_item':
            self.appendResultItem( data.html );
            break;
          case 'additional_info':
            self.appendAdditionalInfo( data );
            break;
          case 'result_not_found':
            self.showIfNoResult();
            break
        }
      },
      loadAdditionalInfo( segmentId, tariffId ){
        this.perform('additional_info', { segment_id: segmentId, tariff_id: tariffId });
      }
    });
  }
  initEvents(){
    let self = this;

    this.results.on('click', `.${this.clsTripDetailsLink}`, function(e) {
      e.preventDefault();
      let detailBlock = $(e.target).closest(`.${self.clsTrain}`).find(`.${self.clsTripDetails}`);
      let tripDetailsLink = $(e.target).closest(`.${self.clsTripDetailsLink}`);

      if(detailBlock.find(`.${self.clsRoutes}`).length == 0){
        self.preloader.show();
        let segmentId = $(e.target).data('segment-id');
        let tariffId = $(e.target).data('tariff-id');

        self.socket.loadAdditionalInfo(segmentId, tariffId);
      }else{
        detailBlock.toggleClass(self.clsHidden);
      }
      tripDetailsLink.toggleClass(self.clsActive);
    });

    this.resultBlock.on('click', `.${this.clsChooseBusBtn}`, function(e) {
      e.preventDefault();
      let form = $(e.target).closest(`.${self.clsChooseBusForm}`);
      
      new Submiter(form).done(function(data){
        self.results.html(data.result.html);
      });
    });

    this.results.on('click', `.${this.clsPassengerInfoBtn}`, function(e) {
      e.preventDefault();
      let form = $(e.target).closest(`.${self.clsPassengerInfoForm}`);
      let passCount = $(`.${self.clsPassCountInput}`).val();

      if( passCount == 0 ){
        new Popup('error', I18n.t('choose_place')).show();
        return;
      }

      new Submiter(form).done(function(data){
        self.results.html(data.result.html);
      });
    });

    this.results.on('click', `.${this.clsReservationBtn}`, function(e) {
      e.preventDefault();
      const form = $(e.target).closest(`.${self.clsReservationForm}`);
      const isFormValid = self.checkPassengersFormValidity(form);
      if (isFormValid) {
        new Submiter(form).done(function(data){
          window.location.href = data.result.redirect_url;
        });
      }
    });

    this.results.on('click', `.${this.clsShowMore}`, function(){
      let hidden_bus = self.resultBlock.find('.'+self.clsTrain+'.'+self.clsHidden);

      hidden_bus.slice(0, 10).toggleClass(self.clsHidden);
      if (hidden_bus.length <= 10) {
        this.showMoreLink.classList.add(self.clsHidden);
      }
    });

    this.results.on('click', `.${this.clsChooseSeat}`, function(e) {
      e.preventDefault();
      let seat = $(e.target);
      let number = seat.data('number');
      let seatId = seat.data('seat-id');
      let cost = parseFloat(seat.data('cost'));

      let passengerInfoForm = self.results.find(`.${self.clsPassengerInfoForm}`);
      let blockSelectedPlaces = self.results.find(`.${self.clsSelectedPlaces}`);
      let passCount = blockSelectedPlaces.find(`.${self.clsSeatContainer}`).length;

      if( seat.hasClass(self.clsSelected) ){
        blockSelectedPlaces.find(`.${self.clsSeatContainer}[data-number=${number}]`).remove();
        self.changePassengerNumber();
        passengerInfoForm.find(`input[value=${number}]`).remove();
        passengerInfoForm.find(`input[value='${seatId}']`).remove();
        passCount--;
      } else {
        blockSelectedPlaces.append( render.selectedBusPlace(number, seatId, cost, ++passCount) );
        passengerInfoForm.append( render.reservationPassengerInput( number, seatId ) );
        self.toggleVisibilitySeatContainer();
      }
      passengerInfoForm.find(`.${self.clsPassCountInput}`).val(passCount);

      seat.toggleClass(self.clsSelected);
      self.calculateTotalCost();
    });

    this.results.on('click', `.${this.clsCancelSelectSeat}`, function(e) {
      e.preventDefault();
      let seatContainer = $(e.target).closest(`.${self.clsSeatContainer}`);
      let seatNumber = seatContainer.data('number');
      let seatId = seatContainer.data('seat-id');
      let seatBlock = self.results.find(`.${self.clsChooseSeat}[data-number='${seatNumber}']`);
      let passengerInput = self.results.find(`.${self.clsPassengerInfoForm} input[value=${seatNumber}]`);
      let passengerSeatIdInput = self.results.find(`.${self.clsPassengerInfoForm} input[value='${seatId}']`);
      let passengerInfoForm = self.results.find(`.${self.clsPassengerInfoForm}`);
      let blockSelectedPlaces = self.results.find(`.${self.clsSelectedPlaces}`);

      seatBlock.removeClass(self.clsSelected);
      seatContainer.remove();
      passengerInput.remove();
      passengerSeatIdInput.remove();

      let passCount = blockSelectedPlaces.find(`.${self.clsSeatContainer}`).length;
      passengerInfoForm.find(`.${self.clsPassCountInput}`).val(passCount);
      
      self.changePassengerNumber();
      self.calculateTotalCost()
    });

    this.results.on('click', `.${this.clsChooseAnotherBus}`, function(e) {
      e.preventDefault();
      window.location.reload();
    });

    this.results.on('click', `.${this.clsIncreasePassCount}`, function(e) {
      e.preventDefault();
      let countBlock = self.results.find(`.${self.clsPassCount}`);
      let count = countBlock.html();
      let passCountInput = self.results.find(`.${self.clsPassengerInfoForm} .${self.clsPassCountInput}`);

      count++;
      countBlock.html(count);
      passCountInput.val( count );
      self.calculateTotalCost();
    });

    this.results.on('click', `.${this.clsReducePassCount}`, function(e) {
      e.preventDefault();
      let countBlock = self.results.find(`.${self.clsPassCount}`);
      let count = countBlock.html();
      let passCountInput = self.results.find(`.${self.clsPassengerInfoForm} .${self.clsPassCountInput}`);

      count = count < 1 ? 0 : --count;
      countBlock.html(count);
      passCountInput.val( count );
      self.calculateTotalCost();
    });

    this.results.on('click', `.${this.clsShowBusStops}`, function(e) {
      e.preventDefault();

      let currentTrip = $(e.target).closest(`.${self.clsTripDetails}`);
      let routeList = currentTrip.find(`.${self.clsRouteList}`);
      let action = routeList.find(`.${self.clsBusStop}.${self.clsHidden}`).length == 0;

      routeList.find(`.${self.clsBusStop}`).toggleClass(self.clsHidden, action);
      routeList.find(`.${self.clsShowBusStops}`).toggleClass(self.clsActive, !action);
    });

    this.results.on('click', `.${self.clsExpandableText}`, function(e) {
      e.preventDefault();
      $(e.target).toggleClass(self.clsActive);
    });

    this.results.on('change', `.${self.clsSelectDiscount}`, function(e) {
      let total_cost = 0;

      $(`.${self.clsSelectDiscount}`).find(":selected").map(function(_, option){
        total_cost += new Number(option.dataset.cost);
      });

      $(`.${self.clsTotalAmount}`).html( render.total_price(total_cost) );
    });

    this.results.on('click', `.${self.clsSortResultTable}`, function(e) {
      e.preventDefault();
      const el    = $(e.target);
      const type  = el.data('type');

      self.results.find(`.${self.clsSortResultTable}`).removeClass(self.clsActive);
      el.addClass(self.clsActive);
      self.sortResultBy(type);
    });
  }

  appendResultItem( html ){
    this.resultBlock.append( html );
    let busPreloader = document.querySelector(`.${this.clsResultPreloader}`);
    let hidden_bus = this.resultBlock.find(`.${this.clsTrain}.${this.clsHidden}`);

    if (hidden_bus.length > 0) {
      this.showMoreLink.classList.remove(this.clsHidden);
    }
    this.isBusResult = true;
    busPreloader.classList.add(this.clsHidden);
    this.busNoResultBlock.addClass(this.clsHidden);
  }
  appendAdditionalInfo( data ){
    let detailBlock = $(`.${this.clsTripDetails}[data-segment-id="${data.segment_id}"]`);
    detailBlock.html( data.html );
    detailBlock.removeClass(this.clsHidden);
    this.preloader.hide();
  }
  changePassengerNumber(){
    let self = this;
    let seatContainer = self.results.find(`.${self.clsSeatContainer}`);
    
    seatContainer.each(function(index, container){
      $(container).find(`.${self.clsPassengerNumber}`).text(index+1);
    });
    
    this.toggleVisibilitySeatContainer();
  }
  toggleVisibilitySeatContainer(){
    let isContainerHidden = this.results.find(`.${this.clsSeatContainer}`).length == 0;

    this.results.find(`.${this.clsSelectedContainer}`).toggleClass(this.clsHidden, isContainerHidden);
  }
  calculateTotalCost(){
    let totalAmountBlock = this.results.find(`.${this.clsTotalAmount}`);
    let oneSeatCost = totalAmountBlock.data('seat-cost');

    let passCount = this.isBusSchemaExist()
                    ? [...this.results.find(`.${this.clsChooseSeat}.selected`) ].length 
                    : parseInt( this.results.find(`.${this.clsPassCount}`).html() );

    totalAmountBlock.html( render.total_price(passCount * oneSeatCost) );
  }
  isBusSchemaExist(){
    return this.results.find(`.${this.clsBusSchema}`).length > 0
  }
  
  checkPassengersFormValidity(form){
    let indicator = true;
    const results = $.map(form.find('.js-bus-user-data'), function(passenger, index){
      if(!indicator) return false;
      
      const $passenger = $(passenger);
   
      const firstname = new Validation('firstname', $passenger.find('.js-bus-document-firstname'));
      if (!firstname.isValid($passenger.find('.js-bus-document-lastname').val() )){
        firstname.showError();
        return indicator = false;
      }

      const lastname     = new Validation('lastname', $passenger.find('.js-bus-document-lastname'));
      if (!lastname.isValid($passenger.find('.js-bus-document-firstname').val() )){
        lastname.showError();
        return indicator = false;
      }

      const email = $passenger.find('.js-bus-document-email');
      if (email.length) {
        const emailValidation = new Validation('email', email);
        if (!emailValidation.isValid()){
          emailValidation.showError();
          return indicator = false;
        }
      }

      const phone = $passenger.find('.js-bus-document-phone');
      if (phone.length) {
        const phoneValidation = new Validation('phone', phone);
        if (!phoneValidation.isValid()){
          phoneValidation.showError();
          return indicator = false;
        }
      }
      
      const birthday = $passenger.find('.js-bus-document-birthday');
      if (birthday.length) {
        const birthdayValidation = new Validation('birthday', birthday);
        if (!birthdayValidation.isValid()){
          birthdayValidation.showError();
          return indicator = false;
        }
      }

      return true;
    });
    return results.every(function(element){return element});
  };

  showIfNoResult(){
    const busPreloader = document.querySelector(`.${this.clsResultPreloader}`);
    if (!this.isBusResult){
      this.busNoResultBlock.toggleClass(this.clsHidden, false);
      busPreloader.classList.add(this.clsHidden);
    }
  };

  sortResultBy(type){
    this.preloader.show();

    const trains = this.results.find(`.${this.clsTrain}`);
    trains.sort((a, b) => {
      return $(a).data(type) - $(b).data(type);
    });

    trains.each((i, el) => {
      $(el).attr('style', `order: ${i};`);
      el.classList.remove(this.clsHidden);
    });
    this.showMoreLink.classList.add(this.clsHidden);

    this.preloader.hide();
  }
}

$(() => {
  let results = $('.js-bus-search-result')
  if (results.length > 0){
    new BusSearchResults(results)
  }
})

export default BusSearchResults