import $ from 'jquery'
import Spinner from '../lib/spinner'
import DataLayerHelper from '../lib/data_layer_helper'


class SearchResults {
  constructor($results) {
    this.results = $results
    this.breadcrumb = this.results.find('.breadcrumb-container')
    this.dates = this.results.find('.dates-list')
    this.results = this.results.find('.results-table')

    this.choosePlaceButtons   = this.results.find('.js-choose-places')
    this.spinner = new Spinner
    this.urlParams = new URLSearchParams(window.location.search)
    this.direction_en =  $('.js-search-route').data('direction-en')

    this.dataLayer = new DataLayerHelper

    this.initialize()
  }

  initialize() {
    

    this.setupSelectTrain()
    this.setupShowRoute()

    this.setScenario()
    this.setupDataLayer()

  }
  setupDataLayer(){
    let self = this;
    let ecommerce = this.results.find('.js-result-item').toArray().map((result, i) => {
      return self.getGTMEcommerceForDataLayer($(result))
    })

    this.dataLayer.push({ ecommerce: null });
    this.dataLayer.push({event: "Ticket request", ecommerce: ecommerce})
  }

  getGTMEcommerceForDataLayer(resultItem) {
    return {
      item_name: this.direction_en,
      item_id: resultItem.data('sort-number'),
      index: resultItem.data('index')
    }
  }

  setScenario() {
    if(/^22/.test(this.urlParams.get('from'))){
      scenario.Local();
    }else{
      scenario.International();
    }
  };

  setupShowRoute() {
    this.results.find('.js-show-route').on('click', (ev)=>{
      ev.preventDefault()
      let form = $(ev.target).siblings('.js-route-form')
      let modal = $('#routeModal')
      this.spinner.show()

      $.ajax({
        method: form.attr('method'),
        data: form.serialize(),
        url: form.attr('action'),
        success: (data, code, xhr) => {
          this.spinner.hide()
          modal.find('.modal-body').html(data)
          modal.modal()
        },
        error: ()=>{
          this.spinner.hide()
        }
      })
    })
  }

  setupSelectTrain() {
    let self = this
    this.choosePlaceButtons.on( 'click', (ev) => {
      self.dataLayer.push({ ecommerce: null })
      self.dataLayer.push({
        event: "Train choice",
        ecommerce: self.getGTMEcommerceForDataLayer($(ev.target).parents('.js-result-item'))
      })
      window.render.train(ev)
    });
    
    // this.results.find('.choose-btn button').on('click', (ev)=>{
    //   let button = $(ev.target)
    //   let resultDetails = button.parents('.results-table-item').find('.results-table-item-details')

    //   if (button.hasClass('active')) {
    //     button.text(button.data( 'text-regular'))
    //     button.removeClass('active')
    //     resultDetails.removeClass('show')
    //     this._toggleShowClass(resultDetails, 'visible', false, 200)
    //   } else {
    //     button.text(button.data( 'text-active'))
    //     button.addClass('active')
    //     resultDetails.addClass('visible')
    //     this._toggleShowClass(resultDetails, 'show', true, 50)
    //   }
    // })
  }
  _toggleShowClass(el, className, state, timeout) {
    setTimeout(() => {
      el.toggleClass(className, state)
    }, timeout);
  }
}

$(() => {
  let results = $('.js-search-result')
  if (results.length > 0){
    new SearchResults(results)
  }
})

export default SearchResults