var DateRange = function(from, to, option){
  this.from   = $(from);
  this.to     = $(to);
  this.option = option || {};
  this.option.minDate  = this.option.minDate || '-1Y';
  this.option.maxDate  = this.option.maxDate || 0;
  this.initialize();
}
DateRange.prototype.from = function(){
  return this.from.val();
};
DateRange.prototype.to = function(){
  return this.to.val();
};
DateRange.prototype.initialize = function(){
  var self = this;
  this.from.datepicker(this.option).on('change', function(){
    self.to.datepicker("option", "minDate", $(this).val());
  });
  this.to.datepicker(this.option).on('change', function(){
    self.from.datepicker("option", "maxDate", $(this).val());
  });
};

export default DateRange