import {Popup} from '../lib/popup'

class ScheduleBetweenStations {
  constructor(form) {
    this.form = form
    this.stationFromEl = this.form.find('[name=code_from]')
    this.stationToEl = this.form.find('[name=code_to]')

    this.setupAutocomplete()
    this.setupFormSubmit()
    this.autocompleteCache = []
  }

  setupAutocomplete() {
    let self = this
    this.form.find('.station-autocomplete').autocomplete({
      delay: 500,
      minLength: 2,
      selectFirst: false,
      autoFocus: false,
      source: function (request, response) {
        var term = request.term;
        if(request.term in self.autocompleteCache){
          response(self.autocompleteCache[term]);
          return;
        }
        window._preloader = undefined;
        $.ajax({
          dataType: 'json',
          url: '/electrichky/stations',
          data: $.param({name: request.term, locale: I18n.locale}),
          success: function (data) {
            self.autocompleteCache[term] = data;
            response(data);
          }
        })
      },
      create: function (event, ui) {
        $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
          return $( '<li>' ).addClass('ui-menu-item-wrapper')
            .attr( 'data-value', item.id )
            .append( item.name  )
            .appendTo( ul );
        };
        $(this).on('click',function(ev){
          ev.preventDefault();
          $(this).trigger('select');
          return false;
        });
      },
      select: function (event, ui) {
        event.preventDefault();
        var element = $(event.target);
        // var nextIndex = parseInt(element.attr('tabindex')) + 1;
        element.val(ui.item.name).siblings('input[type=hidden]').val(ui.item.id);
        // $('[tabindex=' + nextIndex + ']').trigger('focus');
      },
  
      focus: function (event, ui) {
        this.value = ui.item.name;
        $(this).siblings('.code').val(ui.item.code);
        $('.ui-menu-item.ui-state-active').removeClass('ui-state-active');
        $('.ui-menu-item[data-value="'+ui.item.code+'"]').addClass('ui-state-active');
        return false;
      }
    });
  }

  setupFormSubmit() {
    this.form.on('submit', (ev)=>{
      ev.preventDefault();

      if(this.form.find('[name=code_from]').val() == '') {
        new Popup('error',   I18n.t('schedule.select_station_from') ).show();
      } else if (this.form.find('[name=code_to]').val() == '') {
        new Popup('error',   I18n.t('schedule.select_station_to') ).show();
      } else {
        window.location = $('#langMenuButton').data('lang-url') + "/electrichky/between_stations/" + this.getUrlEnd()
      }
    })
  }

  getUrlEnd() {
    return this.stationFromEl.val() + '/' + this.stationToEl.val()
  }
  
}

export default ScheduleBetweenStations