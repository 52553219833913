import {OverAllPopup} from '../popup'
import DateRange from '../date-range'
import Submiter from '../submiter'
import datepicker_locale from '../../i18n-js/datepicker'
import {initTooltip} from '../helper'


var ActualFilter = function(){
  this.form         = $('.js-actual-filter');
  this.result       = $('.js-actual-result');
  this.submitButton = $('.js-filter-actual-submit');
  this.dateFrom     = this.form.find('input[name="start_date"]');
  this.dateTo       = this.form.find('input[name="end_date"]');
  this.dateRange    = new DateRange(this.dateFrom, this.dateTo);
  this.initializeSubmit();
  this.addIndex();
  this.setupEvents();
}

ActualFilter.prototype.setupEvents = function(){
  var self = this;
  $(this.result).on('click', '.js-show-passengers', function(ev){
    var toggleBtn = $(ev.target);
    var bookingEl = toggleBtn.parents('.js-booking');
    var documentEl = bookingEl.next('.js-documents');
    var onlyHide = toggleBtn.hasClass('active');

    self.result.find('.js-show-passengers').removeClass('active');
    self.result.find('.js-documents').removeClass('active show')

    if(!onlyHide) {
      toggleBtn.addClass('active');
      documentEl.addClass('active').delay(100).queue(function(next){
        documentEl.addClass("show");
        next();
      });
    }
  });
}

ActualFilter.prototype.formValid = function(){
  var valid_result = false;
  var parseData = function(value){ return $.datepicker.parseDate(datepicker_locale[I18n.locale]["dateFormat"],value )}
  var start = this.form.find('input[name="start_date"]').val();
  var end =  this.form.find('input[name="end_date"]').val();
  var start_date = parseData(start);
  var end_date   =  parseData(end);
  if(end_date >= start_date){ valid_result = true } 
  if(end.length == 0 && start.length == 0 ){ valid_result = true }
  return valid_result;
}

ActualFilter.prototype.addIndex = function(){
  var count = 1;

  $('.ticket-number').each(function(){
    $(this).text(count);
    count += 1;
  })
}

ActualFilter.prototype.initializeSubmit = function(){
  var self = this;
  this.submitButton.on('click', function(event){
    var allVals = [];
    $('button.active').each(function(){
      allVals.push($(this).val());
    })
    $('#op_types').val(allVals);

    if(self.formValid()){
      new Submiter(self.form).done(function(data){
        self.result.html(data.result).show();
        self.addIndex();
        initTooltip();
      });
    }else{
      new OverAllPopup(I18n.t('filter.validate.global')).show();
    }
  });
}

// $(document).on('turbolinks:load', function () {
$(() => {
  if ($('.js-actual-filter')) {
    new ActualFilter();
  }
});
