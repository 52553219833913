import {Popup} from './popup'

class ScheduleForm {
  constructor(scheduleForm) {
    this.scheduleForm = scheduleForm
    this.routeidSlug = this.scheduleForm.find('[name=slug]')
    this.directionEl = this.scheduleForm.find('[name=reverse]')
    this.autocompleteCache = []

    this.setupAutocomplete()
    this.setupSubmit()
  }

  setupSubmit() {
    this.scheduleForm.on('submit', (ev)=>{
      ev.preventDefault();
      if(this.routeidSlug.val() == '') {
        new Popup('error',   I18n.t('schedule.select_direction') ).show();
      } else {
        window.location = $('#langMenuButton').data('lang-url') + "/electrichky/route/" + this.getUrlEnd()
      }
    })
  }

  getUrlEnd() {
    if (this.directionEl.val() == '0') {
      return this.routeidSlug.val()  
    }

    return this.routeidSlug.val() + '/' + this.directionEl.val()
  }

  setupAutocomplete() {
    let self = this
    this.scheduleForm.find('.routes-autocomplete').autocomplete({
      minLength: 0,
      classes: {
        "ui-autocomplete": "routes-autocomplete-menu"
      },
      source: function (request, response) {
        var term = request.term;
        if(self.autocompleteCache.length > 0){
          let result = self.autocompleteCache;
          if(term.length > 0) {
            result = self.autocompleteCache.filter(route => {
              return route['name'].toLowerCase().search(term.toLowerCase()) != -1;
            })
          }
          response(result);
          return;
        }
        window._preloader = undefined;
        $.ajax({
          url: '/electrichky/routes_list',
          data: $.param({locale: I18n.locale}),
          success: function (data) {
            self.autocompleteCache = data;
            response(data);
          }
        })
      },
      create: function (event, ui) {
        $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
          return $( '<li>' ).addClass('ui-menu-item-wrapper')
            .attr( 'data-value', item.slug )
            .append( item.name )
            .appendTo( ul );
        };
        $(this).data('ui-autocomplete').element.addClass('routes-menu');
        $(this).on('click',function(ev){
          ev.preventDefault();
          $(this).trigger('select');
          return false;
        });
      },
      select: function (event, ui) {
        event.preventDefault();
        var element = $(event.target);
        element.val(ui.item.name).siblings('[name=slug]').val(ui.item.slug);
      },
  
      focus: function (event, ui) {
        this.value = ui.item.name;
        $(this).siblings('.slug').val(ui.item.slug);
        $('.ui-menu-item.ui-state-active').removeClass('ui-state-active');
        $('.ui-menu-item[data-value="'+ui.item.slug+'"]').addClass('ui-state-active');
        return false;
      }
    }).focus(function(){     
      //Use the below line instead of triggering keydown
      $(this).data("uiAutocomplete").search($(this).val());
  });
  }
}

export default ScheduleForm