import Breadcrumbs from './booking/breadcrumbs'
import Submiter from './submiter'
import storeFactory from './booking/store'
import BookingPage from './booking/booking-page'
import SchemaSVG from './booking/schema-svg'
import {Popup} from './popup'
import Results from './search-results'
import PassengersFrom from './booking/passengers-form'
import Basket from './basket'
import Preloader from './preloader'


var Renderer = function(){
  window.breadcrumbs = this.breadcrumbs = new Breadcrumbs();
  this.train = this.train.bind(this);
  this.kyivBoryspilUserData = this.kyivBoryspilUserData.bind(this);
  this.passengersForm = this.passengersForm.bind(this);
  this.backToFirstTrain = this.backToFirstTrain.bind(this);
  this.backToSecondTrain = this.backToSecondTrain.bind(this);
  this.store = null;
};
Renderer.prototype.total_price = function(cost){
  return cost.toFixed(2).replace('.',',') + " " + I18n.t('UAH');
};
Renderer.prototype.home = function(){
  var cart = $('.js-cart-container');
  if(cart.length > 0){
    new Basket(cart);
  }else{
    scenario.setBasketEmpty();
    scenario.removeParent();
  }
};
Renderer.prototype.result = function(searchForm){
  $('html, body').animate({scrollTop: searchForm.submitButton.offset().top}, 2000);
  $('.js-choose-back').remove();
  $('.js-search-with-transfers').remove();
  $('.js-search-direct-trains').remove();
  $('.js-search-result').empty();
  var preloader = new Preloader('train',{ title: I18n.t('preloader.title'),
                                          from:  searchForm.fromName.val(),
                                          to:    searchForm.toName.val()});
  new Submiter(searchForm.form, preloader).done(function(data){
    scenario.setTripType('direct');
    $('.js-search-result').replaceWith(data);
    $('.ticket-search').show();
    new Results($('.js-search-result'));
  });
};
Renderer.prototype.resultWithTransfers = function(event){
  if (searchForm && searchForm.valid()){
    var url = $(event.target).data('url');
    if(!url) url = event;
    new Submiter(searchForm.form).done(function(data){
      window.searchForm.searchData.url = window.searchForm.searchDataSteps.transferSearch;
      window.searchForm.newUrl();

      scenario.setTripType('transfer');
      $('.js-search-with-transfers').remove();
      $('.js-search-result').replaceWith(data);
      new Results($('.js-search-result'));
      $('html, body').animate({
        scrollTop: $('.js-search-result').offset().top
      }, 1000);
    }, null, url);
  }
};
Renderer.prototype.train = function(event){
  var self = this;
  var trainForm = $(event.target).siblings('.js-choose-places-form');
  new Submiter(trainForm).done(function(data){
    $('.js-breadcrumbs').remove();
    $('.js-cart-container .js-choose-back').remove();
    $('.js-main-search-form').hide();
    $('.js-search-result').replaceWith(data);
    window.breadcrumbs = self.breadcrumbs = new Breadcrumbs();
    self.breadcrumbs.show();
    self.breadcrumbs.firstStep();
    self.store = storeFactory();
    $('.ticket-search').hide();
    $('.popular-direction-tags').hide();
    if(self.store){
      new BookingPage($('.js-search-result'), self.store);
      $('html, body').animate({
        scrollTop: $('.js-search-result').offset().top
      }, 1000);
    }
  });
};
Renderer.prototype.routePopup = function(event){
  var routeLink = $(event.target).hasClass('js-show-route') ? $(event.target) : $(event.target).parent();
  var routeFrom = routeLink.siblings('.js-route-form');
  new Submiter(routeFrom).done(function(data){
    new Popup('route', data).show();
  });
};
Renderer.prototype.passengersForm = function(event, store){
  var self = this;
  var form = $(event.target).find('form');

  this.store = store
  if(form.find('[name=documents]').val() == ''){
    new Popup('error',I18n.t(scenario.isLocal() ? 'choose_place' : 'select_place_count')).show();
    return;
  }
  new Submiter(form).done(function(data){
    if (scenario.isTransfer()){
      $('.js-search-result').hide();
      self.breadcrumbs.thirdStep();      
    }else
      self.breadcrumbs.secondStep();
    $('.js-show-route,.js-route-form').hide();
    $('.js-car-scheme-container').hide();
    // $('.js-search-result').find('th:last-child, td:last-child').hide();
    $('.js-search-result').hide();
    $('.js-total-cost').hide();
    $('.js-total-cost').after(data);
    new PassengersFrom($('.js-user-data-block'), store);
    $('html, body').animate({
      scrollTop: $('.js-search-result').offset().top
    }, 1000);
  });
};
Renderer.prototype.backToTrainsList = function(event){
  var self = this;
  var form = $('.js-back-to-result-list');
  new Submiter(form).done(function(data){
    if(typeof searchForm !== 'undefined'){
      searchForm.setDate(form.find('[name=date]').val());
    }
    self.breadcrumbs.remove();
    $('.js-search-result').empty();
    $('.js-total-cost,.js-add-to-basket-block').remove();
    $('.js-car-scheme-container').remove();
    $('.js-pre-card').remove();
    $('.js-user-data-block').remove();
    $('.js-main-search-form').show();
    $('.ticket-search').show();
    $('.popular-direction-tags').show();
    $('.js-search-result').replaceWith(data);
    new Results($('.js-search-result'));
    $('html, body').animate({
      scrollTop: $('.js-search-result').offset().top
    }, 2000);
  }, null, $(event.target).data('url') );
};
Renderer.prototype.backToFirstTrain = function(event){
  var self = this;
  this.breadcrumbs.firstStep();
  if(scenario.isTransfer()){
    var trainForm = $(event.target).find('form');
    new Submiter(trainForm).done(function(data){
      $('.js-user-data-block').remove();
      $('.js-add-to-basket-block').remove();
      $('.js-total-cost').remove();
      $('.js-car-scheme-container').remove();
      $('.js-search-result').replaceWith(data);
      $('.js-choose-second-train').removeClass('hidden');
      new BookingPage($('.js-search-result'), self.store).showStore();
    });
  }else{
    this.backToChoosePlaces(event);
  }
};
Renderer.prototype.backToSecondTrain = function(event){
  this.breadcrumbs.secondStep();
  this.backToChoosePlaces(event);
};
Renderer.prototype.backToChoosePlaces = function(event){
  var self = this;
  if(!scenario.isTransfer()) this.breadcrumbs.firstStep();
  $('.js-show-route,.js-route-form').show();
  $('.js-car-scheme-container').show();
  $('.js-search-result').show();
  $('.js-total-cost').show();
  $('.js-user-data-block').remove();
  $('.js-add-to-basket-block').remove();
};
Renderer.prototype.schema = function(wagon, store){
  this.store = store;
  new Submiter(wagon.siblings('form')).done(function(data){
    wagon.addClass('current');
    wagon.parent().addClass('wagon-tab--active');    
    $('.js-wagon-schema').replaceWith(data);
    if(scenario.isLocal()){
      new SchemaSVG($('.js-wagon-schema'), store);
    }else if(scenario.isInternational()){
      new SchemaSelector($('.js-wagon-schema'), store);
    }
  });
};
Renderer.prototype.basket = function(form){
  new Submiter(form).done(function(data){
    scenario.removeParent();
    scenario.clearCancelTimers();

    $('.js-user-data-block').slideUp();
    $('.js-add-to-basket-block').slideUp();
    $('.js-search-result').remove();
    // $('.js-breadcrumbs').remove();
    $('.js-main-search-form').show();
    self.breadcrumbs.thirdStep();
  });
};
Renderer.prototype.reloadBasket = function(form){
  new Submiter(form).done(function(data){
    scenario.clearCancelTimers();
    $('.js-pay-button-container').remove();
    $('.js-cart-container').replaceWith(data);
    if($('.js-cart-container').find('.cart .travel, .cart .shuttle, .cart .transportation').length < 1){
      $('.js-pay-button-container').remove();
      $('.js-cart-container').remove();
      scenario.setBasketEmpty();
      scenario.removeParent();
    }else{
      // new Basket($('.js-cart-container'));
      window.location.reload();
    }
  }, function(data){
    if(data.type == 'basket_not_found'){
      $('.js-pay-button-container').remove();
      $('.js-cart-container').remove();
    }else if(data.type == 'document_not_found'){
      $('[name=booking][value='+data.result+']').parents('tr').remove();
      if($('.js-cart-container').find('.cart tr').length < 2){
        $('.js-pay-button-container').remove();
        $('.js-cart-container').remove();
        scenario.setBasketEmpty();
        scenario.removeParent();
      }
    }
  });
};
Renderer.prototype.backSearchForm = function(event){
  var button      = $(event.target);
  var data        = button.data();
  var searchForm  = $('.js-main-search-form');

  searchForm.find('.js-from-code').val(data.from);
  searchForm.find('.js-to-code').val(data.to);
  searchForm.find('.js-departure-date').datepicker("setDate", new Date(data.date)).change();
  searchForm.find('.js-from-name').val(data.fromName);
  searchForm.find('.js-to-name').val(data.toName);

  button.hide();
};
Renderer.prototype.popupMessageOnLoad = function(messageBlock){
  if(messageBlock.length > 0){
    new Popup('info', {
      title: messageBlock.find('.js-title').text(),
      content: messageBlock.find('.js-content').text()
    }).show();
  }
};
Renderer.prototype.waitingOnLoad = function(waitingBlock){
  if (waitingBlock.length > 0){
    // new Preloader('round').show();
    var check_basket = setInterval(function(){
      new Submiter(waitingBlock, new Preloader('fake')).done(function(data){
        switch(true) {
          case (data.result == 'approved' || data.result == 'reload'):
            clearInterval(check_basket);
            location.reload();
            break;
          case data.result == 'redirect':
            window.location.href = window.location.origin + data.path;
            break;
        }
      });
    }, 5000);
  }
};

Renderer.prototype.secondTrain = function(event, store){
  var self = this;
  var form = $(event.target).find('form');
  this.store = store;
  if(store.places.length == 0){
    new Popup('error',I18n.t('choose_place_in_first_train')).show();
    return false;
  }
  new Submiter(form).done(function(data){
    $('.js-car-scheme-container').remove();
    $('.js-total-cost').remove();
    $('.js-search-result').replaceWith(data);
    self.breadcrumbs.secondStep();
    new BookingPage($('.js-search-result'), store).showStore();
  });
};

Renderer.prototype.kyivBoryspilTimeTable = function(event){
  var self = this;
  self.popup_el    = $('.js-kyiv-boryspil-timetable');
  
  event.preventDefault();
  $.magnificPopup.open({
      type: 'inline',
      alignTop: false,
      fixedContentPos: false,
      fixedBgPos: true,
      overflowY: 'auto',
      closeBtnInside: true,
      preloader: false,
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-slide-bottom',
      items: {
        src: self.popup_el
      }
    }
  );

};

Renderer.prototype.kyivBoryspiltimetableToggle = function(event){
  $(".js-forward").toggle();
  $(".js-backward").toggle();

};

Renderer.prototype.kyivBoryspilUserData = function(event){
  var self = this;
  var boryspilForm = $(event.target).siblings('.js-choose-places-boryspil-form');
  new Submiter(boryspilForm).done(function(data){
    $('.js-cart-container .js-choose-back').remove();
    $('.js-main-search-form').hide();
    $('.js-search-result').replaceWith(data);
    window.breadcrumbs = self.breadcrumbs = new Breadcrumbs();
    self.breadcrumbs.show();
    self.breadcrumbs.firstStep();  
    scenario.setTripType('boryspil');
    var store = storeFactory();
    if(store){
      new BookingPageBoryspil($('.js-search-result'), store);
      $('html, body').animate({
        scrollTop: $('.js-search-result').offset().top
      }, 1000);
    }
  });
};

Renderer.prototype.selectedBusPlace = function(number, seatId, price, passengerIndex){
  return  `<div class="seat-container js-seat-container" data-number="${number}" data-seat-id="${seatId}">
            <div class="passenger">
              ${I18n.t('passenger')}
              <span class="passenger-number js-passenger-number">${passengerIndex}</span>
            </div>
            <div class="seat-info">
              <div class="number">${I18n.t('place')} ${number}</div>
              <div class="price">${this.total_price(price, 'UAH')}</div>
              <button class="cancel js-cancel-seat-select"></button>
            </div>
          </div>`;
};

Renderer.prototype.reservationPassengerInput = function(number, seatId) {
  return `<input type="hidden" name="places[]" value="${number}">
          <input type="hidden" name="seats[]" value="${seatId}">`;
};

window.scenario = {
  isLocal:          function(){return sessionStorage['scenario'] === 'local';},
  isInternational:  function(){return sessionStorage['scenario'] === 'international';},
  isCargo:          function(){return sessionStorage['scenario'] === 'cargo';},
  isRoundtrip:      function(){return sessionStorage['roundtrip'] === 'true';},
  Local:            function(){sessionStorage['scenario'] = 'local'},
  International:    function(){sessionStorage['scenario'] = 'international'},
  Cargo:            function(){sessionStorage['scenario'] = 'cargo'},
  setRoundtrip:     function(value){sessionStorage['roundtrip'] = value;},

  setParent:        function(value){sessionStorage['parent'] = value;},
  getParent:        function(){return sessionStorage['parent']},
  removeParent:     function(){sessionStorage.removeItem('parent')},

  getCancelTimers:  function(){return sessionStorage['cancelTimers'] && sessionStorage['cancelTimers'].split('|') || []},
  setCancelTimers:  function(timersString){sessionStorage['cancelTimers'] = timersString},
  clearCancelTimers: function(){ $.each(scenario.getCancelTimers(), function(index, ID){ clearInterval(ID) }); scenario.setCancelTimers('') },

  basketHasItems:     function(){return sessionStorage['basketHasItems'] === 'true';},
  setBasketNotEmpty:  function(){sessionStorage['basketHasItems'] = true},
  setBasketEmpty:     function(){sessionStorage['basketHasItems'] = false},

  setTripType: function(value){sessionStorage['tripType'] = value},
  getTripType: function(){return sessionStorage['tripType']},
  isDirect:    function(){return sessionStorage['tripType'] == 'direct'},
  isTransfer:  function(){return sessionStorage['tripType'] == 'transfer'},
  isBoryspil:  function(){return sessionStorage['tripType'] == 'boryspil'},
};

$(() => {
  window.render = new Renderer();
  render.home();
  render.popupMessageOnLoad($('.js-on-load-message-show').remove());
  render.waitingOnLoad($('.js-waiting'));
})

export default Renderer