import Preloader from './preloader'
import {Popup} from './popup'



var Submiter = function(form, preloader){
  this.form = form;
  window._preloader = preloader || new Preloader('round');
};
Submiter.prototype.done = function(render, after_error_callback, action){
  var self = this;
  $.ajax({
    type: 'POST',
    url:  action || this.form.attr('action'),
    data: this.form.serialize(),
    success: function(data, textStatus, jqXHR){
      if(jqXHR.status == 227){
        new Popup('error', data).show();
      }else if(data.error){
        new Popup('error', data['error']).show();
        if( typeof(after_error_callback) === 'function' ){
          after_error_callback(data);
        }
      }else if(data.redirect){
        window.location = data.redirect;
      }else if(data.reload){
        window.location.reload();
      }else{
        render(data);
      }

      if (typeof grecaptcha != 'undefined') {
        // dirty sheet to fix 
        // Uncaught Error: No reCAPTCHA clients exist.
        // TODO find better way to fix
        try {
          grecaptcha.reset()
        } catch (e) {
          setTimeout(function(){
            grecaptcha.reset()
          },3000);
        }
      }
      
    },
    error: function(xhr, status, error){
      new Popup('error', error).show();
    },
  });
};
Submiter.prototype.doneWithFiles = function(render){
  var self = this;
  $.ajax({
    type: 'POST',
    url:  self.form.attr('action'),
    data: new FormData(self.form[0]),
    processData: false,
    cache: false,
    contentType: false,
    mimeType: 'multipart/form-data',
    success: function(data, textStatus, jqXHR){
      data = JSON.parse(data);

      if(jqXHR.status == 227){
        new Popup('error', data).show();
      }else if(data.error){
        new Popup('error', data['error']).show();
      }else if(data.redirect){
        window.location = data.redirect;
      }else{
        render(data);
      }
    },
    error: function(xhr, status, error){
      new Popup('error', error).show();
    },
  });
};

export default Submiter