let datepicker_locale = {}
 
datepicker_locale['ru'] = {
	closeText: "Закрыть",
	prevText: "&#x3C;Пред",
	nextText: "След&#x3E;",
	currentText: "Сегодня",
	monthNames: [ "Январь","Февраль","Март","Апрель","Май","Июнь",
	"Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь" ],
	monthNamesShort: [ "Янв","Фев","Мар","Апр","Май","Июн",
	"Июл","Авг","Сен","Окт","Ноя","Дек" ],
	dayNames: [ "воскресенье","понедельник","вторник","среда","четверг","пятница","суббота" ],
	dayNamesShort: [ "вск","пнд","втр","срд","чтв","птн","сбт" ],
	dayNamesMin: [ "Вс","Пн","Вт","Ср","Чт","Пт","Сб" ],
	weekHeader: "Нед",
	dateFormat: "dd.mm.yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "",
	departureTime: "Время отправления",
	dayParts: ["Утро", "День", "Вечер"] };

datepicker_locale['uk'] = {
		closeText: "Закрити",
		prevText: "&#x3C;",
		nextText: "&#x3E;",
		currentText: "Сьогодні",
		monthNames: [ "Січень","Лютий","Березень","Квітень","Травень","Червень",
		"Липень","Серпень","Вересень","Жовтень","Листопад","Грудень" ],
		monthNamesShort: [ "Січ","Лют","Бер","Кві","Тра","Чер",
		"Лип","Сер","Вер","Жов","Лис","Гру" ],
		dayNames: [ "неділя","понеділок","вівторок","середа","четвер","п’ятниця","субота" ],
		dayNamesShort: [ "нед","пнд","вів","срд","чтв","птн","сбт" ],
		dayNamesMin: [ "Нд","Пн","Вт","Ср","Чт","Пт","Сб" ],
		weekHeader: "Тиж",
		dateFormat: "dd.mm.yy",
		firstDay: 1,
		isRTL: false,
		showMonthAfterYear: false,
		yearSuffix: "",
		departureTime: "Час відправлення",
		dayParts: ["Ранок", "День", "Вечір"] };

datepicker_locale['en'] = {
	closeText: "Done",
	prevText: "Prev",
	nextText: "Next",
	currentText: "Today",
	monthNames: [ "January","February","March","April","May","June",
	"July","August","September","October","November","December" ],
	monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
	dayNames: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
	dayNamesShort: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
	dayNamesMin: [ "Su","Mo","Tu","We","Th","Fr","Sa" ],
	weekHeader: "Wk",
	dateFormat: "dd/mm/yy",
	firstDay: 1,
	isRTL: false,
	showMonthAfterYear: false,
	yearSuffix: "",
	departureTime: "Departure time",
	dayParts: ["Morning", "Noon", "Evening"] };

export default datepicker_locale