import SearchForm from '../../lib/search_form'


class BusSearchForm extends SearchForm {
  setInputs() {
    super.setInputs();
    this.autocompleteUrl  = '/bus/locality';
    this.searchUrl        = '/bus/search';
    this.transfer         = this.form.find('#transfer');
  }
  searchParams(){
    return $.param({
      from:     this.fromCode.val(),
      to:       this.toCode.val(),
      date:     this.date.val(),
      transfer: this.transfer.val()
    });
  }
  searchParams(){
    return $.param({
      from:     this.fromCode.val(),
      to:       this.toCode.val(),
      date:     this.date.val(),
      transfer: this.transfer.is(':checked'),
    })
  };
}

export default BusSearchForm