import {get as getCookie} from 'es-cookie';


class DataLayerHelper {
  constructor(){
    this.dataLayer = window.dataLayer || []
  }

  push(data) {
    data = Object.assign({}, data, { userId: this.getUserToken() });
    this.dataLayer.push(data)
    return this.dataLayer
  }

  getUserToken(){
    this.userToken = this.userToken || getCookie('user_token')
    return this.userToken
  }

  show(){
    console.log(this.dataLayer)
  }
}

export default DataLayerHelper