import Preloader from "../preloader";
import { Popup, OverAllPopup, ConfirmPopup } from "../popup";

var RefundConfirmForm = function() {
  this.uidField = $(".js-uid-field");
  this.fiscalIdField = $(".js-fiscal-id-field");
  this.refundButton = $(".js-refund-button");
  this.confirmUrl = $(".js-confirm-url").data("confirmUrl");
  this.refundSubmit = $(".js-refund-submit");
  window._preloader = new Preloader("round");

  this.initializeEvents();
};

RefundConfirmForm.prototype.initializeEvents = function() {
  var self = this;

  this.uidField.inputmask({
    showMaskOnHover: false,
  });

  this.uidField.on("keypress", function(e) {
    var value = e.target.value.replace(/-+|_+/g, "").toUpperCase();
    e.target.value = value;
  });

  this.fiscalIdField.on("keypress", function(e) {
    const range = (start, end) =>
      new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
    var available_keys = range(48, 57);

    if (available_keys.indexOf(e.keyCode) == -1) {
      return false;
    }
  });

  this.refundButton.on("click", function(e) {
    e.preventDefault();
    _preloader.show();

    $.post({
      url: e.target.href,
      data: { id: e.target.dataset.id },
      success: function(data) {
        _preloader.hide();
        if (data.error) {
          new OverAllPopup(data.error).show();
        } else {
          self.renderConfirmPopup(data);
        }
      },
    });
  });

  this.refundSubmit.on("click", function(e) {
    e.preventDefault();
    _preloader.show();

    var form = $(this).parents(".js-refund-form");
    var formData = form.serializeArray();
    var params = {};

    if (!form.valid()) {
      _preloader.hide();
      return false;
    }

    $.each(formData, (_, i) => (params[i.name] = i.value));

    $.post({
      url: form.attr("action"),
      data: params,
      success: function(data) {
        _preloader.hide();
        if (data.error) {
          new OverAllPopup(data.error).show();
        } else {
          self.renderConfirmPopup(data);
        }
      },
    });
  });
  
  this.sendConfirmRequest = function(document_id, refund_uio){
    _preloader.show();

    $.post({
      url: self.confirmUrl,
      data: { uio: refund_uio },
      success: function(data) {
        _preloader.hide();
        if (data.error) {
          new OverAllPopup(data.error).show();
        }else{
          new Popup('info', {content: data.message}).show();
          self.refundButton.filter('[data-id='+document_id+']').hide();
        }
      },
    });
  };

  this.renderConfirmPopup = function(data) {
    let message =
      I18n.t("decline_popup.confirm_refund") + render.total_price(data.price);

    new ConfirmPopup(message, function(answer){
      if(answer === 'yes'){
        self.sendConfirmRequest(data.document_id, data.uio);
      }
    });
  };
};

$(() => {
  new RefundConfirmForm();
});
