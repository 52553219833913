import $ from 'jquery' 

let instance = null

export default class Spinner {
  constructor(){
    if(instance){
      return instance;
    }

    this.body = $('body')
    this.spinner = $('.full_page_spinner')

    this.instance = this;
  }

  show() {
    this.spinner.addClass('visible')
    this.body.addClass('no-scroll')
  }

  hide() {
    this.spinner.removeClass('visible')
    this.body.removeClass('no-scroll')
  }

}