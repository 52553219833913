export default class SubMenu {
    constructor(){
      this.initializeBlocks();
      this.initializeEvents();
    }
    
    initializeBlocks(){
      this.phoneWrap            = $('.js-phone-wrap');
      this.subMenuTitle         = $('.js-submenu-title');
      this.subMenu              = $('.js-submenu');
      this.phoneNumberDuplicate = $('.js-phone-number-duplicate');
      this.phoneCodeDuplicate   = $('.js-phone-code-duplicate');
      this.phoneCodeSearch      = $('.js-phone-code-search');
      this.phoneCodeValue       = $('.js-phone-code-value');
      this.countryCode          = $('.js-country-code');
  
      this.clsFocus   = 'focus';
      this.clsHidden  = 'hidden';
    }
    
    initializeEvents(){
      var self = this;
      
      $(document).on('click', function(e){
        if( $(e.target).closest('.js-phone-block').length > 0 ) return;
  
        self.hideMenu();
        self.unFocusTitle();
      });
  
      this.subMenuTitle.on('click', function(){
        let flag = self.subMenu.hasClass(self.clsHidden);
        if (flag){
          self.showMenu();
          self.focusTitle();
        } else {
          self.hideMenu();
          self.unFocusTitle();
        }
      }); 
  
      this.countryCode.on('click', function(e){
        var val = $(e.target).data('value');
  
        self.selectCode(val);
        self.hideMenu();
        self.unFocusTitle();
  
        self.phoneCodeDuplicate.val(val);
        self.phoneCodeDuplicate.change();
      });
      
      this.phoneNumberDuplicate.on('focus', () => self.focusTitle() );
      this.phoneNumberDuplicate.on('blur', () => self.unFocusTitle() );
  
      this.phoneCodeSearch.on('keypress', (e) => e.key.search(/[\d\wА-Яа-я]/) > -1 );
      this.phoneCodeSearch.on('keyup', function(e){
        var val = self.capitalize(e.target.value);
        
        if(val.length > 0){
          self.searchPhoneCode(val);
        } else {
          self.showAllPhoneCode();
        }
      });
    }
    
    showMenu() {
      this.subMenu.removeClass(this.clsHidden);
    }
  
    hideMenu(e) {
      this.subMenu.addClass(this.clsHidden);
    }
    
    focusTitle() {
      this.phoneWrap.addClass(this.clsFocus);    
    }
  
    unFocusTitle() {
      this.phoneWrap.removeClass(this.clsFocus);    
    }
  
    showAllPhoneCode(){
      let self = this;
      this.countryCode.each( (_, e) => self.toggleClass($(e), self.clsHidden, false) );
    }
    
    searchPhoneCode(val){
      let self = this;
      this.countryCode.each( (_, e) => self.toggleClass($(e), self.clsHidden, $(e).html().search(val) == -1) );
    }
  
    selectCode(val){
      this.setCodeValue(val);
      this.countryCode.filter( (_, e) => $(e).hasClass('selected') ).removeClass('selected');
      this.countryCode.filter( (_, e) => $(e).data('value') == val ).addClass('selected');
    }
  
    setCodeValue(val){
      this.phoneCodeValue.html('+'+val);
    }
  
    capitalize(string){
      return string.substring(0, 1).toUpperCase() + string.substring(1);
    }
  
    toggleClass(el, cls, flag){
      if(flag){
        el.addClass(cls);
      }else{
        el.removeClass(cls)
      }
    }
  
  }