var Popup = function(type, data, prop){
  this[type](data);

  this.prop = prop;
  this.show = this.show.bind(this);
};
Popup.prototype.show = function(){
  var config = {
    items: {src: this.src},
    type: 'inline',
  }
  if (this.prop && this.prop.callbacks){
    config['callbacks'] = {};
    if (this.prop.callbacks.close) {
      config['callbacks']['close'] = this.prop.callbacks.close;
    }
  }
  $.magnificPopup.open(config);
}
Popup.prototype.m_show = function(){
  $('.js-validation-popup').addClass('active');
}
Popup.prototype.error = function(data){
  this.src   = '#error-popup';
  this.popup = $('.js-error-popup');
  this.popup.find('.popup-title').text(I18n.t('error'));
  this.popup.find('.popup-content p').text(data);
}
Popup.prototype.error_html = function(data){
  this.src   = '#error-popup';
  this.popup = $('.js-error-popup');
  this.popup.find('.popup-title').text(I18n.t('error'));
  this.popup.find('.popup-content p').html(data);
}
Popup.prototype.route = function(data){
  this.src   = '#route-popup';
  this.popup = $('.js-route-popup');
  this.popup.replaceWith(data);
}
Popup.prototype.attention = function(data){
  this.src   = '#error-popup';
  this.popup = $('.js-error-popup');
  this.popup.find('.popup-title').text(I18n.t('attention'));
  this.popup.find('.popup-content p').text(data);
};
Popup.prototype.info = function(data){
  this.src   = '#error-popup';
  this.popup = $('.js-error-popup');
  this.popup.css('max-width', '525px');
  this.popup.find('.popup-title').text(data.title || I18n.t('attention'));
  this.popup.find('.popup-content p').text(data.content);
};
Popup.prototype.mobileInfo = function(data){
  this.src   = '.js-validation-popup';
  this.popup = $('.js-validation-popup');
  this.popup.find('.popup-content p').text(data);
};
Popup.prototype.placeHelper = function(data){
  this.src = '#choose-place-helper-popup';
};
Popup.prototype.addShipping = function(data){
  this.src = '#buy-shipping-document-popup';
};
Popup.prototype.userData = function(data){
  this.src = '#user-data-popup-auth';
};
Popup.prototype.chooseTrains = function(data){
  this.src = '#waiting-list-popup';
  $('.mfp-content').attr('style', 'max-width: inherit');
};


var ConfirmPopup = function(question, callback){
  var src   = '#decline-order-popup';
  var popup = $(src);
  popup.find('.js-decline-question').text(question);
  var self  = this;
  popup.find('button').off();
  popup.find('button').on('click',function(event){
    callback($(event.target).val());
    $.magnificPopup.close();
  });
  $.magnificPopup.open({items: {src: src}, type: 'inline'});
};
var OkPopup = function(title, message, callback){
  var src   = '#success-order-popup';
  var popup = $(src);
  popup.find('.js-success-title').text(title);
  popup.find('.js-success-message').text(message);
  var self  = this;
  popup.find('button').off();
  popup.find('button').on('click', function(event){
    callback($(event.target).val());
    $.magnificPopup.close();
  });
  $.magnificPopup.open({items: {src: src}, type: 'inline'});
};
var configurablePopup = function(id, popup_values, question, callback){
  var src   = id;
  var popup = $(src);
  popup.find('.js-decline-question').text(question);
  var self  = this;
  popup.find('a, button').off();
  popup.find('a, button').on('click',function(event){
    callback($(event.target).val(), popup.find(popup_values).val());
    $.magnificPopup.close();
  });
  $.magnificPopup.open({items: {src: src}, type: 'inline'});
};
var OverAllPopup = function(data){
  this.data        = data;
  this.activeClass = 'active';
  this.selector    = '.js-validation-popup';
  this.popup       = $(this.selector);
  this.popup.find('.popup-content p').html(data);
  
  var self = this;
  $(this.selector + ', .js-close-popup').on('click', function(event){
    self.popup.removeClass(self.activeClass);
  });
  this.show = this.show.bind(this);
  this.route = this.route.bind(this);
};
OverAllPopup.prototype.show = function(){
  this.popup.addClass(this.activeClass);
};
OverAllPopup.prototype.route = function(){
  this.popup.find('.popup-title').hide();
  this.popup.find('.btn-block').hide();
  this.popup.find('.popup-content').attr('style','padding: 0 !important; width: 998px;');
  this.popup.find('.popup-content p .js-route-popup').removeClass('mfp-hide');
  return this;
};


export {Popup, OverAllPopup, configurablePopup, OkPopup, ConfirmPopup}