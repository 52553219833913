import {Popup} from '../popup'
import DataLayerHelper from '../data_layer_helper'


var OrderButton = function(store){
  this.store = store;
  this.store.subscribers.push(this);
  this.onStoreChange = this.onStoreChange.bind(this);

  this.block              = $('.js-total-cost');
  this.totalText          = this.block.find('.js-total-cost-text');
  this.totalPrice         = this.totalText.find('.js-total-cost-price');
  this.button             = this.block.find('.js-total-cost-button');
  this.blockDouble        = $('.js-total-cost-double');
  this.totalTextDouble    = this.blockDouble.find('.js-total-cost-text-double');
  this.totalPriceDouble   = this.totalTextDouble.find('.js-total-cost-price-double');
  this.form               = this.button.find('form');
  this.formData           = this.form.find('[name=documents]');
  this.secondTrainButton  = this.block.find('.js-choose-second-train');

  this.dataLayer = new DataLayerHelper

  this.initializeEvents();
};
OrderButton.prototype.initializeEvents = function(){
  var self = this;

  this.button.on('click', function(event){

    self.dataLayer.push({ ecommerce: null })
    self.dataLayer.push({
      event: "Issue a ticket",
      ecommerce: self.getGTMEcommerceForDataLayer()
    })

    if(self.orderValid()) render.passengersForm(event, self.store);
    else{
      var error_msg = scenario.isTransfer() ? 'amount_choose_place' : 'choose_place';
      new Popup('error',I18n.t(error_msg)).show();      
    }
  });
  this.secondTrainButton.on('click', function(event){
    render.secondTrain(event, self.store);
  });
};

OrderButton.prototype.getGTMEcommerceForDataLayer = function(){
  let documents = JSON.parse(this.button.find('#documents').val())
  return documents.map((doc,i)=>{
    return {
      item_id: doc.train,
      price: doc.comission,
      wagon_type: doc.wagonTypeId
    }
  })
};

OrderButton.prototype.orderValid = function(){
  var places = this.store.groupPlaceBy('train');
  var trains = Object.keys(places);
  switch(true){
    case scenario.isTransfer():
      if(trains.length < 2) return false;
      for(var i = 1; i < trains.length; i++)
        if (trains[i].length != trains[i-1].length) return false;
      break;
    default:
      return trains.length > 0;    
  }
  return true;
};
OrderButton.prototype.onStoreChange = function(){
  if(this.store.places.length == 0){
    this.formData.val('');
    this.totalPrice.text('');
    this.totalPriceDouble.text('');
    this.totalText.hide();
    this.totalTextDouble.hide();
    return;
  }

  this.form.find('[name=clients]').val(JSON.stringify(this.store.groupByClient()));
  this.form.find('[name=documents]').val(JSON.stringify(this.store.places));
  this.form.find('[name=wishes]').val(JSON.stringify((this.store.wishes || {})));
  this.form.find('[name=parent]').val(scenario.getParent());
  this.form.find('[name=roundtrip]').val(scenario.isRoundtrip());

  var total = 0;
  $.each(this.store.places, function(index, storedPlace){
    total += storedPlace.cost.amount;
  });
  if(total > 0){
    this.totalPrice.text(render.total_price(total));
    this.totalText.show();
    this.totalPriceDouble.text(render.total_price(total));
    this.totalTextDouble.show();
  }
};


export default OrderButton