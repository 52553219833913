import {Popup, OkPopup, ConfirmPopup} from './popup'
import Submiter from './submiter'

class CustomFefundForm {
  constructor(form){
    this.form = form
    this.email_regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    

    this.email = form.find('[name=email]')
    this.phone = form.find('[name=phone]')
    this.submitBtn = form.find('.js-submit-custom-refund-form')

    this.initializeEvents()
  }

  initializeEvents(){
    var self = this;
    this.submitBtn.on('click', (ev)=>{
      ev.preventDefault()
      if (this.contactFormValid()){
        new ConfirmPopup(I18n.t('decline_popup.refund_document'),function(answer){
          if(answer === 'yes'){
            new Submiter(self.form).done(function(data){
              new OkPopup(data.result.title, data.result.message, ()=>{
                window.location = '/'
              })
            })
          }
        })
      }
    })
  }

  contactFormValid(){
    if (!this.validateEmail()) { return false; }

    return true
  }

  validateEmail(){
    if (!!this.email.length){
      if (this.email.val().length == 0){
        new Popup('error', I18n.t('user_email_required')).show();
        return false;
      }
      if (!this.email_regex.test(this.email.val())){
        new Popup('error', I18n.t('user_email_email')).show();
        return false;
      }
    }
    return true;
  };

}

$(() => {
  let customRefundForm = $('.js-custom-refund-form')
  if (customRefundForm.length) {
    new CustomFefundForm(customRefundForm)
  }
})