import {ConfirmPopup} from '../../lib/popup';
import Submiter from '../../lib/submiter';

class BusCabinet {
  constructor($page) {
    this.page = $page;

    this.clsHidden              = 'hidden';
    this.clsActive              = 'active';
    this.clsReturnLink          = 'js-bus-return';
    this.clsTicketType          = 'js-booking-filter';
    this.clsBookingToggle       = 'js-booking-toggle';
    this.clsBookingDetailsBlock = 'js-booking-details';
    this.clsBooking             = 'js-cabinet-item';

    this.initEvents();
  }
  initEvents(){
    let self = this;

    this.page.on('click', `.${this.clsReturnLink}`, function(e) {
      e.preventDefault();

      new ConfirmPopup(I18n.t('decline_popup.cancel_document'), function(answer){
        if(answer === 'yes'){
          let form = $(e.target).closest(`form`);

          new Submiter(form).done(function(data){
            if(data.success) window.location.reload();
          });
        }
      });
    });

    this.page.on('click', `.${this.clsBookingToggle}`, function(e) {
      e.preventDefault();
      let detailBlock = $(e.target).closest(`.${self.clsBooking}`).find(`.${self.clsBookingDetailsBlock}`);
      let bookingToggleButton = $(e.target).closest(`.${self.clsBookingToggle}`);

      detailBlock.toggleClass(self.clsHidden);
      bookingToggleButton.toggleClass(self.clsActive);
    });

    this.page.on('change', `.${this.clsTicketType}`, function(e) {
      let form = $(e.target).closest(`form`);
      form.submit();
    });
  }
}


$(() => {
  let page = $('.js-bus-cabinet, .js-railway-cabinet')
  if (page.length > 0){
    new BusCabinet(page)
  }
})

export default BusCabinet